<template>
    <div class="tools">
        <div class="banner"></div>
        <TitleCard :titleInfo="titleInfo"></TitleCard>
        <div class="img">
            <img src="@/assets/images/tools/tools.png" alt="">
            <div class="none">正在开发，敬请期待</div>
        </div>
        <Statistics></Statistics>
    </div>
</template>

<script>
import TitleCard from "@/components/TitleCard.vue";
import Statistics from "@/components/Statistics.vue"
import { addStay } from "@/api/home/index";

export default {
    components: {
        TitleCard,
        Statistics,
    },
    data() {
        return {
            titleInfo: {
                name: "常用工具",
                enName: "often tools",
            },
            stopTime: ''
        }
    },
    created() {
        this.stopTime = new Date().getTime()
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 3, time: this.stopTime }).then((res) => {
        })
    },
}
</script>
<style lang="scss" scoped>
.tools {
    margin-top: 40px;

    .img {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        height: calc(100vh - 393px);


        img {
            width: 197px;
            height: 197px;
        }

        .none {
            color: #373737;
            font-family: PingFangSCBold;
            font-size: 14px;
        }
    }

}
</style>