// localStorage 的存储
export const setCath = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

// localStorage 的读取
export const getCache = (key) => {
    const value = window.localStorage.getItem(key);
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
        return value;
    }
};

// localStorage 的删除
export const deleteCatch = (key) => {
    window.localStorage.removeItem(key);
};

// 删除所有的 localStorage
export const clearCache = () => {
    window.localStorage.clear();
};