<template>
    <div class="news">
        <!-- banner开始 -->
        <div :class="['banner', $i18n.locale == 'en' ? 'banner-en' : '']">
            <div class="title">{{$t('news.title1')}}</div>
            <div class="content">{{$t('news.title2')}}</div>
        </div>
        <!-- 新闻动态 -->
        <TitleCard :titleInfo="$t('news.titleInfo')"></TitleCard>
        <NewsUpdate></NewsUpdate>
        <Statistics></Statistics>

    </div>
</template>

<script>
import { addStay } from "@/api/home/index";
import TitleCard from '@/components/TitleCard.vue';
import NewsUpdate from './component/NewsUpdate.vue';
import Statistics from '@/components/Statistics.vue'
export default {
    components: {
        TitleCard,
        NewsUpdate,
        Statistics
    },
    data() {
        return {
            id: null,
            titleInfo: {
                name: "新闻动态",
                enName: "News update",
            },
            stopTime: '',
        }
    },
    created() {
        this.stopTime = new Date().getTime()
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 3, time: this.stopTime }).then((res) => {
        })
    },
}
</script>

<style lang="scss" scoped>
.news {
    .banner {
        width: 375px;
        height: 224px;
        background: url("@/assets/images/news/bannerBg.jpg"), no-repeat center center;
        background-size: cover;
        padding: 78px 90px 40px 30px;
        box-sizing: border-box;

        .title {
            color: #262D37;
            font-family: PingFangSCBold;
            font-size: 22px;
            line-height: 150%;
        }

        .content {
            color: #262D37;
            font-family: PingFangSCBold;
            font-size: 12px;
            line-height: 217.5%;
            margin-top: 14px;
        }

    }

    .banner-en {
        .title {
            font-family: Poppins-SemiBold;
            width: 313px;
            font-size: 20px;
            word-break: keep-all !important;
        }
    }
}
</style>