import Vue from 'vue'
import App from './App.vue'
import router from '@/router' //引入路由
import '@/css/reset.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import i18n from './lang'
import 'amfe-flexible'
// 标签内联样式px转rem
import {px2rem} from './utils/rem.js'
Vue.prototype.$px2rem = px2rem
Vue.config.productionTip = false
import { config1 } from "@/utils/request";
Vue.prototype.$config = config1
new Vue({
  i18n,
  router,//注册路由
  render: h => h(App),
}).$mount('#app')
