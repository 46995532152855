<template>
  <!-- 专业团队 -->
  <div :class="['professionalTeam', $i18n.locale == 'en' ? 'professionalTeam-en' : '']">
    <div class="team-top">
      <div class="top-title">{{ bottomList[0]?.titleA }}</div>
      <div class="top-tips">
        <div
          :class="['tip', $i18n.locale == 'en' ? 'tip1' : '']"
          v-for="(item, index) in bottomList[0]?.labelA.split('、')"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="top-content">
        {{ bottomList[0]?.contentA }}
      </div>
      <div class="top-title">{{ bottomList[0]?.titleB }}</div>
      <div class="top-tips">
        <div
          class="tip"
          v-for="(item, index) in bottomList[0]?.labelB.split('、')"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="top-content">
        {{ bottomList[0]?.contentB }}
      </div>
      <div class="top-title">{{ bottomList[0]?.titleC }}</div>
    </div>

    <div class="team-bottom">
      <div
        class="bottomList"
        v-for="(item, index) in smallIconList"
        :key="index"
      >
        <div class="contxt">
          <div class="bottom-title">{{ item.title }}</div>
          <div class="bottom-img"><img :src="$config.proxy + item.pic" /></div>
        </div>
        <div class="bottom-content">{{ item.num }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { professionList, smallIconList } from "@/api/home";
export default {
  data() {
    return {
      bottomList: [],
      smallIconList: [],
    };
  },
  created() {
    this.getprofessionList();
    this.getsmallIconList();
  },
  methods: {
    getprofessionList() {
      professionList({ pageNum: 1, pageSize: 1, status: "1" }).then((res) => {
        this.bottomList = res.rows;
      });
    },
    getsmallIconList() {
      smallIconList({ pageNum: 1, pageSize: 4, type: "4" }).then((res) => {
        this.smallIconList = res.rows;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.professionalTeam {
  .team-top {
    padding: 20px 15px;
    border-radius: 10px 10px 0 0;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 12px;

    .top-title {
      color: #373737;
      // text-align: center;
      font-family: PingFangSCBold;
      font-size: 16px;
      line-height: 217.5%;
      display: flex;
      align-items: center;
    }

    .top-title::before {
      content: "";
      display: block;
      background-image: url("@/assets/images/about/teamIcon.png");
      background-size: 100% 100%;
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }

    .top-tips {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 0 15px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(55, 55, 55, 0.2);
      margin-bottom: 10px;

      .tip {
        padding: 5px 7px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #2a7eff;
        background: #fff;
        color: #2a7eff;
        font-family: PingFangSCMedium;
        font-size: 14px;
        margin-right: 8px;
      }
      .tip1:nth-child(1) {
        margin-bottom: 8px;
      }
    }

    .top-content {
      color: rgba(55, 55, 55, 0.8);
      font-family: PingFangSCMedium;
      font-size: 14px;
      line-height: 189.5%;
      margin-bottom: 10px;
    }
  }

  .team-bottom {
    padding: 0px 15px 10px;
    border-radius: 0 0 10px 10px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 12px;

    .bottomList {
      border-radius: 4px;
      // border: 2px solid #FFF;
      background: linear-gradient(
        180deg,
        rgba(242, 247, 255, 0.6) 0%,
        rgba(215, 224, 239, 0.6) 100%
      );
      box-shadow: 1px 2px 2px 0px rgba(255, 255, 255, 0.7) inset,
        -1px -1px 4px 0px rgba(207, 220, 240, 0.8) inset,
        3px 2px 10px 0px rgba(154, 169, 194, 0.3);
      backdrop-filter: blur(10px);
      padding: 14px 16px 16px 10px;
      margin-bottom: 12px;

      .contxt {
        display: flex;
        justify-content: space-between;
      }

      .bottom-title {
        color: #2a7eff;
        font-family: PingFangSCBold;
        font-size: 14px;
        line-height: 32.5px;
      }

      .bottom-img {
        width: 46.992px;
        height: 32.318px;

        img {
          width: 100%;
        }
      }

      .bottom-content {
        margin-right: 50px;
        color: rgba(55, 55, 55, 0.6);
        font-family: PingFangSCMedium;
        font-size: 12px;
        line-height: 18.5px;
      }
    }
  }
}
.professionalTeam-en {
  .team-top {
    .top-title {
      font-family: Poppins-Medium;
    }
    .top-tips {
      .tip1 {
        font-family: Poppins-Regular;
      }
    }
    .top-content {
      font-family: Poppins-Regular;
    }
  }
  .team-bottom {
    .bottomList {
      .contxt {
        .bottom-title {
          font-family: Poppins-Medium;
        }
      }

      .bottom-content {
        font-family: Poppins-Regular;
      }
    }
  }
}
</style>