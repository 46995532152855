<template>
    <div :class="['buttonCard', $i18n.locale == 'en' ? 'buttonCard1' : '']" @click="jump(ButtonInfo?.path)">
        <div class="name">{{ ButtonInfo?.name ? ButtonInfo.name : $t('ButtonInfo.name') }}</div>
        <img class="next" src="@/assets/images/home/next.png" alt="">
    </div>
</template>

<script>
export default {
    props: {
        ButtonInfo: {
            type: Object,
            required: false
        },
    },
    methods: {
        jump(path) {
            if (path) {
                this.$router.replace({path: path})
            } else {
                console.log('/');
                this.$router.replace({path: '/about'})
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.buttonCard {
    display: flex;
    height: 32px;
    padding: 9px 10px;
    border-radius: 20px;
    background: #2A7EFF;
    justify-content: space-between;
    align-items: center;
    width: 89px;
    box-sizing: border-box;

    .next {
        height: 10px;
    }

    .name {
        font-family: PingFangSCMedium;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
    }
}
.buttonCard1 {
    width: 114px;
    .name {
        font-family: Poppins-Regular;
    }
}
</style>