// zn.js
export default {
  lang: {
    name: 'En'
  },
  menu: {
    name1: '首页',
    name2: '关于恒研',
    name3: '产品中心',
    name4: '解决方案',
    name5: '新闻动态',
    name6: '联系我们'
  },
  ButtonInfo: {
    name: '了解更多'
  },
  home: {
    title1: '解决方案',
    title2: '企业级物联网',
    title3: '十年匠心沉淀,专注智慧物联',
    subTitle: 'loT万物互联网定制中心',
    button: '立即咨询',
    bannerList: [
      {
          url: require('@/assets/images/home/home_banner_bg1.png'),
          title1: '企业数字化转型服务商',
          title2: '为能源、电力行业提供最佳解决方案和专业化服务',
          button: '立即咨询',
      },{
          url: require('@/assets/images/home/home_banner_bg2.png'),
          title1: '企业数字化转型服务商',
          title2: '智能仓储——一站式仓储管理专家',
          button: '立即咨询',
      },{
          url: require('@/assets/images/home/home_banner_bg3.png'),
          title1: '企业数字化转型服务商',
          title2: '数字医疗——快速对接，全面提高就诊效率',
          button: '立即咨询',
      },{
          url: require('@/assets/images/home/home_banner_bg4.png'),
          title1: '企业数字化转型服务商',
          title2: '线上购物——大中型企业电商转型的共同选择',
          button: '立即咨询',
      },
    ],
    titleInfo: {
      num: "01",
      name: "公司简介",
      enName: "COMPANY PROFILE",
    },
    titleInfo1: {
      num: "02",
      name: "发展团队",
      enName: "DEVELOPMENT TEAM",
    },
    titleInfo2: {
      num: "03",
      name: "发展历程",
      enName: "DEVELOPMENT HISTORY",
    },
    titleInfo3: {
      num: "04",
      name: "核心技术",
      enName: "CORE TECHNOLIGY",
    },
    titleInfo4: {
      num: "05",
      name: "公司动态",
      enName: "COMPANY NEWS",
    },
    titleInfo5: {
      num: "06",
      name: "特色产品",
      enName: "CHARACTERISTIC PRODUCTS",
    },
    titleInfo6: {
      num: "07",
      name: "合作企业",
      enName: "COOPERATIVE ENTERPRISES",
    },
    product: {
      titleList: [
        {
          name: '国内项目',
        }, {
          name: '外资合营项目'
        }, {
          name: '海外项目',
        }
      ],
    }
  },
  about: {
    title1: '恒研科技',
    title2: '以专业优质的服务',
    title3: '助力客户更好的提升数字化水平',
    titleInfo: {
      name: "公司简介",
      enName: "COMPANY PROFILE",
    },
    titleInfo1: {
      name: "发展历程",
      enName: "DEVELOPMENT HISTORY",
    },
    titleInfo2: {
      name: "企业理念",
      enName: "CORE technology",
    },
    titleInfo3: {
      name: "专业团队",
      enName: "Professional team",
    },
    titleInfo4: {
      name: "企业资质",
      enName: "Enterprise Qualification",
    },
    titleInfo5: {
      name: "团队风采",
      enName: "Team Style",
    }
  },
  product: {
    title1: '企业数字化',
    title2: '升级解决方案提供商',
    title3: '十年匠心沉淀,专注智慧物联',
    ljzx:"立即咨询",
    titleList: ['国内项目', '外资合营项目', '海外项目'],
    titleInfo: {
      name: "国内项目",
      enName: "Domestic projects",
    },
    titleInfo1: {
      name: "外资合营项目",
      enName: "Foreign joint venture projects",
    },
    titleInfo2: {
      name: "海外项目",
      enName: "Overseas projects",
    },
  },
  news: {
    title1: '深度定制解决方案，成为您的专属团队',
    title2: '更多行业资讯，公司动态等你了解',
    titleInfo: {
      name: "新闻动态",
      enName: "News update",
    },
    NewsDetails: {
      fbrq: "发布日期",
      syp:"上一篇",
      xyp:"下一篇",
      
    },
    NewsUpdate:{
      nomore:"没有更多了",
    }

  },
  contact: {
    title: "和优秀的人，做有挑战的事",
    titleList: ['加入我们', '联系我们'],
    titleInfo: {
      name: "组织结构",
      enName: "Organizational structure",
    },
    titleInfo1: {
      name: "工作岗位",
      enName: "Job post",
    },
    joinUs: {
      gwyq: "岗位要求",
      lxr: "联系人",
      yx: "邮箱",
      gwzz: "岗位职责",
      xzdy: "薪资待遇",
      jlyx: "简历发至招聘邮箱"
    },
  },
  bottomBar: {
    // address: '山西综改示范区太原唐槐园区龙盛街高新技术企业孵化基地4号楼635-638室'
    address: '北京市海淀区东北旺中关村软件园信息中心A307'
  }
};