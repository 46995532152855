<template>
    <div :class="['product-details', $i18n.locale == 'en' ? 'product-details-en' : '']">
        <!-- banner开始 -->
        <div class="banner">
            <div class="banner-title">{{ $t('product.title1') }}</div>
            <div class="banner-title">{{ $t('product.title2') }}</div>
            <div class="banner-content">{{ $t('product.title3') }}</div>
            <div class="banner-button" @click="openChat">
                <div class="name">{{ $t('product.ljzx') }}</div>
                <img src="@/assets/images/product/next.png" alt="">
            </div>
        </div>
        <!-- banner结束 -->

        <div :class="['NewsDetails', $i18n.locale == 'en' ? 'NewsDetails-en' : '']">
            <div class="detailsTitle">{{ item.name }}</div>
            <!-- <div class="detailsTime">{{ $t('news.NewsDetails.fbrq') }} {{ item.createTime }}</div>/ -->
            <div class="detailsContent" v-html="item.detailContent"></div>
        </div>
    </div>
</template>

<script>
import { productList } from "@/api/home/index";

export default {
    data() {
        return {
            item: {},
        }
    },
    methods: {
        openChat() {
            window.open('https://work.weixin.qq.com/kfid/kfc1a69b24cfb6ff8cf')
        },
    },
    mounted() {
        productList({ pageNum: 1, pageSize: 1, id: this.$route.query.id }).then((res) => {
            this.item = res.rows[0]
            if (this.item.detailContent) {
                this.item.detailContent = res.rows[0].detailContent.replaceAll('/dev-api', this.$config.proxy).replaceAll('/prod-api', this.$config.proxy).replaceAll('/api', 'https://sp.etres-ty.com');
            }
        })
    },
}
</script>
<style lang="scss" scoped>
::v-deep .detailsContent {
    p {
        img {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.product-details {
    .banner {
        width: 100%;
        height: 220px;
        padding: 60px 0 40px 30px;
        box-sizing: border-box;
        color: #262D37;
        background: url("@/assets/images/product/banner.png") no-repeat center center;
        background-size: cover;

        .banner-title {
            font-family: 'PingFangSCHeavy';
            font-size: 22px;
            line-height: 1.5;

        }

        .banner-content {
            font-family: 'PingFangSCBold';
            font-size: 12px;
            padding: 11px 0;
        }

        .banner-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 7px 9px;
            box-sizing: border-box;
            margin-top: 5px;
            width: 90px;
            border-radius: 6px;
            border: 2px solid #FFF;
            background: linear-gradient(180deg, #F1F3F8 0%, #FFF 100%);
            box-shadow: 9px 6px 39px 0px rgba(206, 211, 225, 0.80), -9px -8px 28px 0px rgba(255, 255, 255, 0.80), -3px -3px 7px 0px rgba(255, 255, 255, 0.80), 3px 4px 11px 0px rgba(206, 211, 225, 0.80);

            .name {
                font-family: PingFangSCBold;
                font-size: 12px;
                color: #2A7EFF;
            }

            img {
                width: 11px;
                height: 11px;
            }
        }
    }

    .titleList {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleCard {
            width: 33.3%;
            height: 50px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.40) 100%);
            color: #373737;
            text-align: center;
            font-family: PingFangSCMedium;
            font-size: 16px;
            line-height: 50px;
        }

        .activeCard {
            font-family: PingFangSCHeavy;
            color: #2A7EFF;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, #FFF 100%);
        }
    }
}

.product-details-en {
    .banner {
        padding: 85px 0 15px 30px;

        .banner-title {
            font-family: Poppins-SemiBold;
            font-size: 20px;
            line-height: 28px;
        }

        .banner-button {
            .name {
                font-family: Poppins-Medium;
            }
        }
    }

    .titleList {
        .titleCard {
            font-family: Poppins-Regular;
        }

        .activeCard {
            font-family: Poppins-SemiBold;
        }
    }
}

.NewsDetails {
    padding: 20px 12px;
    margin: 20px 12px;
    // margin-bottom: 40px;
    border-radius: 8px;
    background: #E6EBF5;
    box-shadow: 2px 2px 15px 0px rgba(165, 180, 206, 0.40) inset, -4px 0px 8px 0px rgba(255, 255, 255, 0.70) inset;

    .detailsTitle {
        color: #262D37;
        font-family: PingFangSCBold;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 10px;

    }

    .detailsTime {
        color: rgba(55, 55, 55, 0.60);
        font-family: PingFangSCMedium;
        font-size: 12px;
        line-height: 217.5%;
        margin: 5px 0 12px 0;

    }

    .detailsContent {
        border-top: 1px solid rgba(55, 55, 55, 0.20);
        // border-bottom: 1px solid rgba(55, 55, 55, 0.20);
        padding: 12px 0 0 0;
        color: rgba(55, 55, 55, 0.60);
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 180%;

    }

    .end {
        color: #2A7EFF;
        font-family: PingFangSCMedium;
        font-size: 14px;
        margin-top: 20px;
    }
}

.NewsDetails-en {
    .detailsTitle {
        font-family: Poppins-Medium;
    }

    .detailsTime {
        font-family: Poppins-Medium;
    }

    .detailsContent {
        font-family: Poppins-Regular;
    }

    .end {
        font-family: Poppins-Medium;
        line-height: 1.2;
    }
}
</style>