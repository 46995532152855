<template>
  <div class="contact">
    <!-- banner开始 -->
    <div class="banner" :class="$i18n.locale != 'en'?'bannerEn':''">
      <img src="@/assets/images/contact/joinUs.png" />
      <div class="banner-tiitle" v-if="$i18n.locale != 'en'">{{ $t("contact.title") }}</div>
    </div>
    <!-- banner结束 -->
    <!-- tab切换 -->
    <!-- 中文的 -->
    <div class="titleList" v-if="$i18n.locale != 'en'">
      <div
        :class="['titleCard', active == index ? 'activeCard' : '']"
        v-for="(item, index) in $t('contact.titleList')"
        :key="index"
        @click="change(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 英文的 -->
    <div class="titleList" v-if="$i18n.locale == 'en'">
      <div
        :class="[
          'titleCard titleCardEn',
          active == index ? 'activeCardEn' : '',
        ]"
        v-for="(item, index) in $t('contact.titleList')"
        :key="index"
        @click="change(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 组织结构 -->
    <TitleCard
      :titleInfo="
        active == 1 ? $t('contact.titleInfo') : $t('contact.titleInfo1')
      "
    ></TitleCard>
    <Organizational v-if="active == 1"></Organizational>
    <!-- 加入我们 -->
    <joinUs v-if="active == 0"></joinUs>
  </div>
</template>

<script>
import TitleCard from "@/components/TitleCard.vue";
import Organizational from "./component/Organizational.vue";
import joinUs from "./component/joinUs.vue";
import { addStay } from "@/api/home/index";

export default {
  components: {
    TitleCard,
    Organizational,
    joinUs,
  },
  data() {
    return {
      active: 0,
      stopTime: "",
    };
  },

  methods: {
    change(index) {
      this.active = index;
    },
  },
  created() {
    this.stopTime = new Date().getTime();
  },
  beforeDestroy() {
    this.stopTime = new Date().getTime() - this.stopTime;
    addStay({ unitType: 1, type: 5, time: this.stopTime }).then((res) => {});
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .banner {
    width: 100%;
    height: 220px;
    padding-top: 80px;
    padding-bottom: 49px;
    box-sizing: border-box;
    // background: url("@/assets/images/contact/bannerBg.png") no-repeat center
    //   center;
    background-image: url("@/assets/images/contact/bannerBg.png");
    background-position: center 40px;
    background-repeat: no-repeat;
    background-size: 100% calc(100% - 40px);

    img {
      width: 220px;
      height: 75px;
      margin-left: 16px;
    }

    .banner-tiitle {
      color: #262d37;
      font-family: PingFangSCBold;
      font-size: 12px;
      font-weight: 400;
      margin-left: 33px;
    }
  }
  .bannerEn {

  }
  .titleList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleCard {
      line-height: 50px;
      color: #373737;
      text-align: center;
      font-family: PingFangSCBold;
      font-size: 16px;
      width: 187.5px;
      height: 50px;
      flex-shrink: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.04) 0%,
        rgba(255, 255, 255, 0.4) 100%
      );
    }
    .titleCardEn {
      font-size: 14px;
      font-family: Poppins-Regular;
    }

    .activeCard {
      color: #2a7eff;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        #fff 100%
      );
    }
    .activeCardEn {
      font-size: 16px;
      font-family: Poppins-SemiBold;
      color: #2a7eff;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        #fff 100%
      );
    }
  }
}
</style>
