<template>
    <div class="titleCard">
        <div :class="['num',$i18n.locale == 'en' ? 'num1' : '']">{{titleInfo.num}}</div>
        <div class="title">
            <img class="leftIcon" v-if="$i18n.locale == 'en'" src="@/assets/images/home/right.png" alt="">
            <img class="leftIcon" v-if="$i18n.locale != 'en'" src="@/assets/images/home/left.png" alt="">
            <div :class="['name',$i18n.locale == 'en' ? 'name1' : '']">{{titleInfo.name}}</div>
            <img class="rightIcon" v-if="$i18n.locale == 'en'" src="@/assets/images/home/left.png" alt="">
            <img class="rightIcon" v-if="$i18n.locale != 'en'" src="@/assets/images/home/right.png" alt="">

        </div>
        <div class="enName">{{titleInfo.enName}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        titleInfo: {
            type: Object,
            required: false 
        },
    },
}
</script>

<style lang="scss" scoped>
.titleCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // box-sizing: border-box;
    height: 95px;


    .num {
        position: absolute;
        top: 2px;
        left: 135px;
        font-size: 30px;
        font-family: PingFangSCHeavy;
        line-height: 65px;
        color: #D0D6E2;
        z-index: 0;

    }
    .num1 {
        left: 50px;
        top: 10px;
        color: rgba(208, 214, 226, 0.20);
        font-family: Poppins-Medium;
        font-size: 50px;
    }

    .title {
        display: flex;
        align-items: center;
        margin-top: 15px;
        z-index: 0;

        .leftIcon,
        .rightIcon {
            width: 37px;
        }

        .name {
            font-family: PingFangSCHeavy;
            font-size: 18px;
            line-height: 25px;
            color: #373737;
            // font-weight: 600;
            margin: 0 25px;

        }
        .name1 {
            margin: 0 10px;
            text-transform: uppercase;
            font-family: Poppins-SemiBold;
        }
    }

    .enName {
        font-family: PingFangSCMedium;
        font-size: 12px;
        line-height: 17px;
        color: #373737;
        margin-top: 3px;
        text-transform: uppercase;
    }
}
</style>