<template>
    <div class="news">
        <!-- banner开始 -->
        <div :class="['banner', $i18n.locale == 'en' ? 'banner-en' : '']">
            <div class="title">{{ $t('news.title1') }}</div>
            <div class="content">{{ $t('news.title2') }}</div>
        </div>
        <!-- 新闻动态 -->
        <TitleCard :titleInfo="$t('news.titleInfo')"></TitleCard>
        <div :class="['NewsDetails', $i18n.locale == 'en' ? 'NewsDetails-en' : '']">
            <div class="detailsTitle">{{ newsDetail.title }}</div>
            <div class="detailsTime">{{ $t('news.NewsDetails.fbrq') }} {{ newsDetail.date }}</div>
            <div class="detailsContent" v-html="newsDetail.context"></div>
            <div class="end" v-if="backDetail && backDetail.length == 1" @click="getDetail(backDetail[0].id)">
                {{ $t('news.NewsDetails.syp') }}：{{ backDetail[0].title }}</div>
            <div class="end" v-if="nextDetail && nextDetail.length == 1" @click="getDetail(nextDetail[0].id)">
                {{ $t('news.NewsDetails.xyp') }}：{{ nextDetail[0].title }}</div>
        </div>
    </div>
</template>

<script>
import { addStay, getDynamicDetail } from "@/api/home/index";

import TitleCard from '@/components/TitleCard.vue';
export default {
    components: {
        TitleCard,
    },
    data() {
        return {
            titleInfo: {
                name: "新闻动态",
                enName: "News update",
            },
            newsDetail: {},
            backDetail: [],
            nextDetail: [],
            stopTime: ''
        }
    },
    methods: {
        getDetail(id) {
            getDynamicDetail({ id }).then(res => {
                this.newsDetail = res.rows[0]
                this.newsDetail.context = res.rows[0].context.replaceAll('/dev-api', this.$config.proxy).replaceAll('/prod-api', this.$config.proxy);
                this.backDetail = res.rows.filter((item) => {
                    return item.order == 0
                })
                this.nextDetail = res.rows.filter((item) => {
                    return item.order == 1
                })
            })
        },
    },
    created() {
        this.stopTime = new Date().getTime()
        const id = this.$route.params && this.$route.params.id;
        this.getDetail(id)
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 4, time: this.stopTime }).then((res) => {
        })
    },
}
</script>
<style lang="scss" scoped>
::v-deep .detailsContent {
    p {
        img {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.news {
    .banner {
        width: 375px;
        height: 224px;
        background: url("@/assets/images/news/bannerBg.jpg"), no-repeat center center;
        background-size: cover;
        padding: 78px 90px 40px 30px;
        box-sizing: border-box;

        .title {
            color: #262D37;
            font-family: PingFangSCBold;
            font-size: 22px;
            line-height: 150%;
        }

        .content {
            color: #262D37;
            font-family: PingFangSCBold;
            font-size: 12px;
            line-height: 217.5%;
            margin-top: 14px;
        }

    }

    .banner-en {
        .title {
            font-family: Poppins-SemiBold;
            width: 313px;
            font-size: 20px;
            word-break: keep-all !important;
        }
    }

    .NewsDetails {
        padding: 20px 12px;
        margin: 0 12px;
        margin-bottom: 40px;
        border-radius: 8px;
        background: #E6EBF5;
        box-shadow: 2px 2px 15px 0px rgba(165, 180, 206, 0.40) inset, -4px 0px 8px 0px rgba(255, 255, 255, 0.70) inset;

        .detailsTitle {
            color: #262D37;
            font-family: PingFangSCBold;
            font-size: 16px;
            line-height: 25px;

        }

        .detailsTime {
            color: rgba(55, 55, 55, 0.60);
            font-family: PingFangSCMedium;
            font-size: 12px;
            line-height: 217.5%;
            margin: 5px 0 12px 0;

        }

        .detailsContent {
            border-top: 1px solid rgba(55, 55, 55, 0.20);
            border-bottom: 1px solid rgba(55, 55, 55, 0.20);
            padding: 12px 0 16px 0;
            color: rgba(55, 55, 55, 0.60);
            font-family: PingFang SC;
            font-size: 14px;
            line-height: 180%;

        }

        .end {
            color: #2A7EFF;
            font-family: PingFangSCMedium;
            font-size: 14px;
            margin-top: 20px;
        }
    }

    .NewsDetails-en {
        .detailsTitle {
            font-family: Poppins-Medium;
        }
        .detailsTime {
            font-family: Poppins-Medium;
        }
        .detailsContent {
            font-family: Poppins-Regular;
        }
        .end {
            font-family: Poppins-Medium;
            line-height: 1.2;
        }
    }
}
</style>