<template>
  <!-- 企业理念 -->
  <div :class="['philosophy', $i18n.locale == 'en' ? 'philosophy-en' : '']">
    <div class="teamList" v-for="(item, index) in teamList" :key="index">
      <div class="itemTop">
        <img class="titleIcon" src="@/assets/images/about/titleIcon.png" />
        <div class="title">{{ item.title }}</div>
      </div>
      <div class="itemBottom">
        <div :class="[
    item.title == '管理理念' || item.title == 'Management philosophy'
      ? 'glln'
      : item.title == '经营理念' || item.title == '人才理念' || item.title == 'Talent concept' || item.title == 'Business philosophy'
        ? 'jyrcln'
        : item.title == '服务理念' || item.title == 'Service philosophy'
          ? 'fwln'
          : '', $i18n.locale == 'en' ? 'itemBottom-en' : ''
  ]">
          <div :class="['context', $i18n.locale == 'en' ? 'context1' : '']" v-html="item.context"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teamList } from "@/api/home";
export default {
  data() {
    return {
      teamList: [],
    };
  },
  created() {
    this.getteamList();
  },
  methods: {
    getteamList() {
      teamList({ pageNum: 1, pageSize: 4, status: "1" }).then((res) => {
        this.teamList = res.rows.reverse();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .glln {
  .context1 {
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    p:nth-child(3),
    p:nth-child(4) {
      margin-top: 8px;
    }
  }

  p {
    border-radius: 4px;
    background: #e8eaf0;
    padding: 3px 8px;
    box-sizing: border-box;
  }
}

::v-deep .jyrcln {
  p {
    margin-top: 10px;
    display: flex;
    // align-items: center;
  }

  p::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #2A7EFF;
    border-radius: 50%;
    margin-right: 9px;
    flex-shrink: 0;
    margin-top: 7px;
  }

  p:nth-child(1) {
    margin-top: 0;
  }
}

::v-deep .fwln {
  .context1 {

    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4) {
      margin-top: 10px;
    }
  }

  .context {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  p {
    display: flex;
    align-items: center;
  }

  p::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-image: url("@/assets/images/about/fwlnIcon.png");
    background-size: 100% 100%;
    margin-right: 9px;
  }

  p:nth-child(3),
  p:nth-child(4) {
    margin-top: 10px;
  }
}

::v-deep .itemBottom-en {
  .context1 {

    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4) {
      margin-top: 10px;
    }

    p {
      font-family: Poppins-Regular;
      display: flex;
      align-items: center;
      margin-left: 28px;
    }

    p::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background-image: url("@/assets/images/about/fwlnIcon.png");
      background-size: 100% 100%;
      margin-right: 9px;
    }
  }
}
</style>
<style lang="scss" scoped>
.philosophy {
  border-radius: 8px;
  // border: 2px solid #FFF;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 12px;
  padding: 0 16px;
  box-sizing: border-box;

  .teamList {
    display: flex;
    flex-direction: column;

    .itemTop {
      display: flex;
      align-items: center;
      margin: 15px 0 20px 0;

      .titleIcon {
        width: 20px;
        height: 20px;
      }

      .title {
        color: #373737;
        font-family: PingFangSCBold;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .itemBottom {
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(55, 55, 55, 0.2);

      .context {
        color: #373737;
        font-family: PingFangSCMedium;
        font-size: 14px;
        line-height: 20px;
      }

      .glln {
        .context {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  .teamList:last-child {
    .itemBottom {
      border-bottom: none;
    }
  }
}

.philosophy-en {
  .teamList {
    .itemTop {
      .title {
        font-family: Poppins-Medium;
      }
    }
  }
}
</style>