<template>
  <div class="enterpriseQualification">
    <div v-for="(item, index) in enterList" :key="index" class="enterCard">
      <div :class="['enter-title', $i18n.locale == 'en' ? 'enter-title-en' : '']">
        {{ $i18n.locale == 'en' ? item.name1 : item.name }}
      </div>
      <div class="enter-list" v-if="item.picList.length > 0">
        <swiper
          :options="enterOption"
          class="swiper-container"
          ref="enterSwiper"
        >
          <swiper-slide
            class="swiper-slide"
            v-for="(iitem, iindex) in item.picList"
            :key="iindex"
          >
            <div class="swiper-content">
              <div class="enter-imgBox">
                <img class="enter-img" :src="$config.proxy+iitem.pic" alt="" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { qualificationList } from "@/api/home";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.enterSwiper.swiper;
    },
  },
  data() {
    return {
      enterList: [
        {
          name: "资质认证",
          name1: 'Qualification certification',
          picList: [],
        },
        {
          name: "专利软著",
          name1: 'Patent soft work',
          picList: [],
        },
        {
          name: "公司荣誉",
          name1: 'Company Honor',
          picList: [],
        },
      ],
      enterOption: {
        // simulateTouch: false,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 20,
        loopAdditionalSlides: 100,
        slidesPerView: 2.5,
        loop: true,
        speed: 3000,
        autoplay: {
          delay: 1, //自动切换的时间间隔
          reverseDirection: true, //反向滚动
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      qualificationList({
        pageNum: 1,
        pageSize: 9999,
        status: "1",
      }).then((res) => {
        let data = res.rows;
        data.forEach((item) => {
          switch (item.type) {
            case "1":
              this.enterList[0].picList.push({ pic: item.pic });
              break;
            case "2":
              this.enterList[1].picList.push({ pic: item.pic });
              break;
            case "3":
              this.enterList[2].picList.push({ pic: item.pic });
              break;
            default:
              break;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.enterpriseQualification {
  display: flex;
  padding: 20px 0 28px 0;
  margin: 0 12px;
  flex-direction: column;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  .enterCard {
    margin-top: 30px;
    .enter-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #373737;
      text-align: center;
      font-family: PingFangSCBold;
      font-size: 16px;
      line-height: 217.5%;
      margin-bottom: 10px;
    }
    .enter-title-en {
      font-family: Poppins-Medium;
    }

    .swiper-slide {
      .swiper-content {
        display: flex;
        align-items: center;
        height: 150px;
      }

      .enter-imgBox {
        border-radius: 4px;
        background: rgba(42, 126, 255, 0.6);
        box-sizing: border-box;
        padding: 6px 6px 0 6px;

        .enter-img {
          min-width: 85px;
          max-width: 115px;
          min-height: 85px;
          max-height: 135px;
          background-size: contain;
          border-radius: 4px;
          box-shadow: 0px 4px 13px 0px #2772e3;
        }
      }
    }

    .swiper-slide-active {
      .enter-imgBox {
        min-width: 95px;
        max-height: 145px;
      }
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      .enter-imgBox {
        min-width: 85px;
        max-height: 145px;
      }
    }
  }
  .enterCard:first-child {
    margin-top: 0;
  }
}
</style>