import axios from "axios";
import router from "../router/index";
import { getCache } from "../utils";
const config = { 
  // proxy: "/api", // 正式服务器地址(代理跨域)
  proxy: "https://sp.etres-ty.com", // 正式服务器地址
  // proxy: "http://111.53.214.119:8904", // 测试服务器地址
  // proxy: "http://192.168.1.20:8082", // 本地地址
  // proxy: "http://192.168.1.168:58082", // 本地地址
};
const config1 = {
  // proxy: "http://192.168.1.168:58082/image", // 本地图片地址
  // proxy: "http://111.53.214.119:8904/image", // 测试服务器图片地址
  proxy: "https://sp.etres-ty.com/image", // 正式服务器图片地址
  // proxy: "/api/image", // 正式服务器图片地址(代理跨域)
}
// axios基本配置
const api = axios.create({
  timeout: 30000,
  withCredentials: true,
});
// 请求开始拦截
api.interceptors.request.use(
  (conf) => {
    return conf;
  },
  (error) => ({ status: 0, msg: error.message })
);
// 请求返回拦截
api.interceptors.response.use(
  (response) => {
    if (
      response.data.code === -1 &&
      response.data.msg === "验签失败，请重新登录"
    ) {
      router.push({ name: "Login" });
      localStorage.clear();
    }
    return Promise.resolve(response).then(checkCode);
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 后台自定义 code错误处理
const checkCode = (res) => {
  if (res) {
    if (res.data.code === 200) {
      //code为0成功
      const returnData = {
        code: 200,
        success: true,
        rows: res.data.rows ? res.data.rows : res.data.data ? res.data.data : [],
        total: res.data.total ? res.data.total : null,
        msg: res.data.message,
      };
      return returnData;
    } else {
      //统一处理错误
      const returnData = {
        success: false,
        code: res.data.code,
        msg: res.data.message || res.data.msg,
      };
      return returnData;
    }
  } else {
    const returnData = {
      success: false,
      code: -1,
      msg: "没有返回值",
    };
    return returnData;
  }
};

// 解析参数
const formatParams = (method = "GET", params) => {
//   let userInfo = JSON.parse(localStorage.getItem("accountInfo"));
//   let token = userInfo ? userInfo.token : null;
  // let token = null
  //headers配置
  const headers = {
    "Content-Type": "application/json;charset=utf-8",
    "Language": getCache('lang') == 'en' ? 'en' : '' ,
  };
  switch (method) {
    case "POST":
      return {
        headers,
        method,
        data: params,
      };
    case "PUT":
      return {
        headers,
        method,
        data: params,
      };
    case "DELETE":
      return {
        headers,
        method,
        data: params,
      };
    case "GET":
      return {
        headers,
        method,
        params,
      };
    default:
      return {
        headers,
        method,
        params,
      };
  }
};

export { api, formatParams, config, config1 };