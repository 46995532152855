<template>
    <div class="circleCard"
        :style="{ width: $px2rem(circleData?.size ? circleData.size : '73px'), height: $px2rem(circleData?.size ? circleData.size : '73px') }">
        <van-circle v-model="circleData.currentRate" :rate="circleData.currentRate" stroke-width="60"
            :size="circleData?.size ? circleData.size : $px2rem('73px')" :clockwise="false" :color="circleData.color"
            :layer-color="circleData.layerColor" />
        <div class="text" :style="{ color: circleData.color }">{{ circleData?.text ? circleData.text :
            circleData.currentRate.toFixed(0) + '%' }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        circleData: {
            type: Object,
            required: false,
        },
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.circleCard {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .text {
        position: absolute;
        top: calc(50% - 8px);
        width: 50px;
        text-align: center;
        left: calc(50% - 25px);
        font-family: PingFangSCBold;
    }
}</style>