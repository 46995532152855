<template>
    <div class="statistics">
        <div class="list" v-for="(item, index) in list" :key="index">
            <div class="num">{{ item.num }}</div>
            <div class="name"> {{ item.appTitle }}</div>
        </div>
    </div>
</template>

<script>
import { smallIconList } from "@/api/home/index";
export default {
    data() {
        return {
            list: []
        }
    },
    methods: {
        init() {
            smallIconList({ pageNum: 1, pageSize: 4, type: 2 }).then((res) => {
                this.list = res.rows
            })
        }
    },
    created () {
        this.init();
    },
}
</script>

<style lang="scss" scoped>
.statistics {
    display: flex;
    width: 375px;
    height: 87px;
    padding: 22px 26px;
    justify-content: space-between;
    align-items: center;
    background: #F4F9FF;
box-sizing: border-box;
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .num {
            color: #2A7EFF;
            font-family: PingFangSCHeavy;
            font-size: 22px;
            line-height: 217.5%;
            /* 47.85px */
        }

        .name {
            font-family: PingFangSCBold;
            color: #262D37;
            font-size: 12px;
        }
    }
}
</style>