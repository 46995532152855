// en.js
export default {
    lang: {
        name: '中'
    },
    menu: {
        name1: 'Home Page',
        name2: 'About Hengyan',
        name3: 'Product Center',
        name4: 'Solution',
        name5: 'News Updates',
        name6: 'Contact Us'
    },
    ButtonInfo: {
        name: 'Learn more'
    },
    home: {
        title1: 'Ten years of precipitation',
        title2: 'Focus on smart',
        title3: 'IoT',
        subTitle: 'loT Internet Customization Center consult / Enterprise loT solutions',
        button: 'Consult',
        bannerList: [
            {
                url: require('@/assets/images/home/home_banner_bg1.png'),
                title1: 'Focus on smart IoT',
                title2: 'To provide the best solutions and professional services for the energy and power industry',
                button: 'Consult',
            },{
                url: require('@/assets/images/home/home_banner_bg2.png'),
                title1: 'Focus on smart IoT',
                title2: 'Intelligent Warehousing - one-stop warehousing management expert',
                button: 'Consult',
            },{
                url: require('@/assets/images/home/home_banner_bg3.png'),
                title1: 'Focus on smart IoT',
                title2: 'Digital health care - rapid docking, comprehensively improve the efficiency of medical treatment',
                button: 'Consult',
            },{
                url: require('@/assets/images/home/home_banner_bg4.png'),
                title1: 'Focus on smart IoT',
                title2: 'Online shopping -- the common choice of e-commerce transformation for large and medium-sized enterprises',
                button: 'Consult',
            },
        ],
        titleInfo: {
            num: "ONCE",
            name: "COMPANY PROFILE",
            enName: "",
        },
        titleInfo1: {
            num: "SCCOND",
            name: "DEVELOPMENT TEAM",
            enName: "",
        },
        titleInfo2: {
            num: "THIRD",
            name: "DEVELOPMENT HISTORY",
            enName: "",
        },
        titleInfo3: {
            num: "FOURTH",
            name: "CORE TECHNOLIGY",
            enName: "",
        },
        titleInfo4: {
            num: "FIFTH",
            name: "COMPANY NEWS",
            enName: "",
        },
        titleInfo5: {
            num: "SIXTH",
            name: "CHARACTERISTIC PRODUCTS",
            enName: "",
        },
        titleInfo6: {
            num: "SEVENTH",
            name: "COOPERATIVE ENTERPRISES",
            enName: "",
        },
        product: {
            titleList: [
                {
                    name: 'Domestic',
                }, {
                    name: 'Joint venture'
                }, {
                    name: 'Overseas',
                }
            ],
        }
    },
    about: {
        title1: 'Eternal Research Co.,Ltd',
        title2: 'by providing professional and high-quality services,we aim to help customers better enhance their digitalization',
        title3: '',
        titleInfo: {
            name: "COMPANY PROFILE",
            enName: "",
        },
        titleInfo1: {
            name: "DEVELOPMENT HISTORY",
            enName: "",
        },
        titleInfo2: {
            name: "CORE Technology",
            enName: "",
        },
        titleInfo3: {
            name: "Professional Team",
            enName: "",
        },
        titleInfo4: {
            name: "Enterprise Qualification",
            enName: "",
        },
        titleInfo5: {
            name: "Team Style",
            enName: "",
        }
    },
    product: {
        title1: 'Provider of enterprise digital',
        title2: 'upgrade solutions',
        title3: '',
        ljzx:"consult",
        titleList: ['Domestic', 'Joint Venture', 'Overseas'],
        titleInfo: {
            name: "Domestic projects",
            enName: "",
        },
        titleInfo1: {
            name: "Foreign joint venture",
            enName: "",
        },
        titleInfo2: {
            name: "Overseas projects",
            enName: "",
        },
    },
    news: {
        title1: 'Deeply customized solutions, becoming your exclusive team',
        title2: '',
        titleInfo: {
            name: "News update",
            enName: "",
        },
        NewsDetails: {
            fbrq: "Release Date",
            syp: "Prev",
            xyp: "Next",

        },
        NewsUpdate:{
            nomore:"No more",
          }
    },
    contact: {
        title: "Do challenging things with outstanding people",
        titleList: ['JOIN US', 'CONTACT US'],
        titleInfo: {
            name: "Organizational structure",
            enName: "",
        },
        titleInfo1: {
            name: "Job post",
            enName: "",
        },
        joinUs: {
            gwyq: "Job requirements",
            lxr: "Contact person",
            yx: "Email",
            gwzz: "Responsibility",
            xzdy: "Salary and benefits",
            jlyx: "Send resume to recruitment email"
        },
    },
    bottomBar: {
    //   address: 'Room 635, 6 floor, building 4, incubation base No.18, Longsheng Street, Tanghuai Park, Shanxi comprehensive reform demonstration zone.'
      address: 'A307, Dongbei Wangzhongguancun Software Park Information Center, Haidian District, Beijing'
    }
}