<template>
    <div :class="['developHistory',$i18n.locale=='en' ? 'developHistory-en' : '']">
        <div class="contentBox">
            <div class="historyList" v-for="(item, index) in courseList" :key="index">
                <div class="time">{{ item.date }}</div>
                <div class="content"> {{ item.intro }}</div>
            </div>
            <div class="line"></div>
            <div class="more">
                <ButtonCard></ButtonCard>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonCard from "@/components/ButtonCard.vue";

export default {
    components: {
        ButtonCard,
    },
    props: {
        courseList: {
            type: Array,
            required: false 
        },
    },
}
</script>

<style lang="scss" scoped>
.developHistory {
    width: 100%;
    background: url("@/assets/images/home/developHistory.png") no-repeat center center;
    background-size: cover;

    .contentBox {
        padding: 0 12px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .historyList {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 43%;
            margin-right: 15px;

            .time {
                border-radius: 4px;
                background: rgba(42, 126, 255, 0.13);
                padding: 5px 6px;
                color: #373737;
                font-family: PingFangSCBold;
                font-size: 14px;
                line-height: 1;
                text-align: center;
                margin-bottom: 10px;
            }

            .content {
                color: rgba(55, 55, 55, 0.80);
                font-family: PingFangSCMedium;
                font-size: 12px;
                // text-align: end;
                line-height: 1.5;
                // height: 56px;
                display: -webkit-box;
                /* 将元素设置为弹性盒子布局 */
                -webkit-box-orient: vertical;
                /* 设置弹性盒子的排列方向为垂直 */
                overflow: hidden;
                /* 隐藏溢出部分的内容 */
                -webkit-line-clamp: 3;
                /* 设置最大显示行数为3行 */
                text-overflow: ellipsis;
            }
        }

        .historyList:nth-child(even) {
            align-items: flex-start;
            margin-left: 16px;
            margin-top: 30px;
            margin-right: 0;

            .content {
                text-align: start;
            }
        }

        .historyList:nth-child(5) {
            margin-right: 53%;

        }

        .line {
            height: 100%;
            border-left: 1px dashed #373737;
            position: absolute;
            left: 50%;
            top: 0;
        }

        .more {
            position: absolute;
             bottom: 0;
             right: 15px;
        }

    }
}
.developHistory-en {
    .contentBox {
        .historyList {
            .time {
                font-family: Poppins-Medium;
            }
            .content {
                font-family: Poppins-Regular;
            }
        }
    }
}
</style>