<template>
    <div :class="['product', $i18n.locale == 'en' ? 'product-en' : '']">
        <!-- banner开始 -->
        <div class="banner">
            <div class="banner-title">{{ $t('product.title1') }}</div>
            <div class="banner-title">{{ $t('product.title2') }}</div>
            <div class="banner-content">{{ $t('product.title3') }}</div>
            <div class="banner-button" @click="openChat">
                <div class="name">{{ $t('product.ljzx') }}</div>
                <img src="@/assets/images/product/next.png" alt="">
            </div>
        </div>
        <!-- banner结束 -->
        <!-- 项目切换 -->
        <!-- <div class="titleList">
            <div :class="['titleCard', active == index ? 'activeCard' : '']" v-for="(item, index) in $t('product.titleList')" :key="index"
                @click="change(index)">
                {{ item }}
            </div>
        </div> -->
        <!-- 项目 -->
        <TitleCard :titleInfo="titleInfo"></TitleCard>
        <domesticProjects :projectsList="projectsList"></domesticProjects>
    </div>
</template>

<script>
import { addStay, productList } from "@/api/home/index";
import TitleCard from "@/components/TitleCard.vue";
import domesticProjects from "./component/domesticProjects.vue";
export default {
    components: {
        TitleCard,
        domesticProjects
    },
    data() {
        return {
            active: 0,
            projectsList: [],
            stopTime: '',
            dictValue: null,
            type: null,
            titleInfo: {},
        }
    },
    watch: {
        $route(to, from) {
            this.dictValue = to.query.dictValue ? to.query.dictValue : null
            this.type = to.query.type ? to.query.type : null
            this.titleInfo = {
                name: this.$i18n.locale != 'en' ? to.query.dictLabel : to.query.remark,
            }
            this.getproductList()
        }
    },
    methods: {
        // change(index) {
        //     this.active = index
        //     this.getproductList()
        // },
        getproductList() {
            productList({ pageNum: 1, pageSize: 9999, titleType: this.dictValue ? this.dictValue : '', type: this.type, status: 1 }).then((res) => {
                this.projectsList = res.rows
            })
        },
        openChat() {
            window.open('https://work.weixin.qq.com/kfid/kfc1a69b24cfb6ff8cf')
        }
    },
    mounted() {
        this.stopTime = new Date().getTime()
        this.dictValue = this.$route.query.dictValue ? this.$route.query.dictValue : null
        this.type = this.$route.query.type ? this.$route.query.type : null
        this.titleInfo = {
            name: this.$i18n.locale != 'en' ? this.$route.query.dictLabel : this.$route.query.remark,
        }
        this.getproductList()
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 2, time: this.stopTime }).then((res) => {
        })
    },
}

</script>

<style lang="scss" scoped>
.product {
    .banner {
        width: 100%;
        height: 220px;
        padding: 60px 0 40px 30px;
        box-sizing: border-box;
        color: #262D37;
        background: url("@/assets/images/product/banner.png") no-repeat center center;
        background-size: cover;

        .banner-title {
            font-family: 'PingFangSCHeavy';
            font-size: 22px;
            line-height: 1.5;

        }

        .banner-content {
            font-family: 'PingFangSCBold';
            font-size: 12px;
            padding: 11px 0;
        }

        .banner-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 7px 9px;
            box-sizing: border-box;
            margin-top: 5px;
            width: 90px;
            border-radius: 6px;
            border: 2px solid #FFF;
            background: linear-gradient(180deg, #F1F3F8 0%, #FFF 100%);
            box-shadow: 9px 6px 39px 0px rgba(206, 211, 225, 0.80), -9px -8px 28px 0px rgba(255, 255, 255, 0.80), -3px -3px 7px 0px rgba(255, 255, 255, 0.80), 3px 4px 11px 0px rgba(206, 211, 225, 0.80);

            .name {
                font-family: PingFangSCBold;
                font-size: 12px;
                color: #2A7EFF;
            }

            img {
                width: 11px;
                height: 11px;
            }
        }
    }

    .titleList {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleCard {
            width: 33.3%;
            height: 50px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.40) 100%);
            color: #373737;
            text-align: center;
            font-family: PingFangSCMedium;
            font-size: 16px;
            line-height: 50px;
        }

        .activeCard {
            font-family: PingFangSCHeavy;
            color: #2A7EFF;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, #FFF 100%);
        }
    }
}

.product-en {
    .banner {
        padding: 85px 0 15px 30px;

        .banner-title {
            font-family: Poppins-SemiBold;
            font-size: 20px;
            line-height: 28px;
        }

        .banner-button {
            .name {
                font-family: Poppins-Medium;
            }
        }
    }

    .titleList {
        .titleCard {
            font-family: Poppins-Regular;
        }

        .activeCard {
            font-family: Poppins-SemiBold;
        }
    }
}
</style>