<template>
    <!-- 核心技术 -->
    <div class="coreTechnology" v-if="technologyInfo.length > 0">
        <div :class="['list', $i18n.locale == 'en' ? 'listEn' : '']" v-for="(item, index) in technologyInfo " :key="index">
            <div class="title">{{ item.name }}</div>
            <div class="content" v-html="item.context"></div>
            <img class="img " src="@/assets/images/home/core.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        technologyInfo: {
            type: Array,
            required: false
        },
    },
}
</script>

<style lang="scss" scoped>
.coreTechnology {
    display: flex;
    // margin: 0 0 0 12px;
    overflow-x: auto;
    overflow-y: hidden;


    .list {
        position: relative;
        width: 138px;
        height: 184px;
        border-radius: 10px;
        border: 2px solid #FFF;
        background: linear-gradient(180deg, #F1F3F8 0%, #FFF 100%);
        box-shadow: 9px 6px 39px 0px rgba(206, 211, 225, 0.80), -9px -8px 28px 0px rgba(255, 255, 255, 0.80), -3px -3px 7px 0px rgba(255, 255, 255, 0.80), 3px 4px 11px 0px rgba(206, 211, 225, 0.80);
        margin-right: 17px;
        flex-shrink: 0;
        padding: 16px 15px;
        box-sizing: border-box;
        margin-bottom: 20px;

        .title {
            color: #373737;
            font-family: PingFangSCBold;
            font-size: 16px;
            line-height: 21px;
            display: -webkit-box;
            /* 将元素设置为弹性盒子布局 */
            -webkit-box-orient: vertical;
            /* 设置弹性盒子的排列方向为垂直 */
            overflow: hidden;
            /* 隐藏溢出部分的内容 */
            -webkit-line-clamp: 2;
        }

        .content {
            color: rgba(55, 55, 55, 0.80);
            font-family: PingFangSCMedium;
            font-size: 12px;
            line-height: 19.5px;
            margin-top: 10px;
            display: -webkit-box;
            /* 将元素设置为弹性盒子布局 */
            -webkit-box-orient: vertical;
            /* 设置弹性盒子的排列方向为垂直 */
            overflow: hidden;
            /* 隐藏溢出部分的内容 */
            -webkit-line-clamp: 6;
            /* 设置最大显示行数为6行 */
        }

        .img {
            width: 107px;
            height: 91px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .list:first-child {
        margin-left: 15px;
    }

    .listEn {
        width: 188px;
        height: 270px;
        // word-break: ;
        .title {
            font-family: Poppins-Medium;
        }
        .content {
            font-family: Poppins-Regular;
            -webkit-line-clamp: 9;
            /* 设置最大显示行数为6行 */
        }
    }
}

.coreTechnology::-webkit-scrollbar {
    height: 0;
    /* 隐藏滚动条 */
}
</style>