<template>
  <div class="newsUpdate">
    <van-list v-model="loading" :finished="finished" :finished-text="$t('news.NewsUpdate.nomore')" @load="onLoad"
      :immediate-check="false" :offset="0" ref="scrollRef">
      <div v-if="$i18n.locale != 'en'">
        <van-cell class="newsList" v-for="(item, index) in newsList" :key="index" @click="jump(item)">
          <div class="news-title">{{ item.title }}</div>
          <div class="news-content">{{ item.intro }}</div>
          <div class="news-bottom">
            <div class="news-time">{{ item.date }}</div>
            <img src="@/assets/images/news/next.png" alt="" />
          </div>
        </van-cell>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <van-cell class="newsList newsList-en" v-for="(item, index) in newsList" :key="index" @click="jump(item)">
          <div class="news-content">
            <div class="news-title">{{ item.title }}</div>
            <div class="news-time">{{ item.date }}</div>
          </div>
          <div class="news-bottom">
            <img :src="$config.proxy + item.pic" alt="" />
          </div>
        </van-cell>
      </div>
    </van-list>
    <!-- <div class="newsList" v-for="(item, index) in newsList" :key="index" @click="jump(item)">
            <div class="news-title">{{ item.title }}</div>
            <div class="news-content">{{ item.intro }}</div>
            <div class="news-bottom">
                <div class="news-time">{{ item.date }}</div>
                <img src="@/assets/images/news/next.png" alt="">
            </div>

        </div> -->
  </div>
</template>
<script>
import { dynamicList } from "@/api/home/index";
export default {
  data() {
    return {
      newsList: [],
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 999,
      total: 0,
    };
  },
  created() {
    this.getnewsList();
  },

  methods: {
    jump(item) {
      this.$router.push({
        name: "NewsDetail",
        params: {
          id: item.id,
        },
      });
    },
    onLoad() {
      this.loading = true;
      this.getnewsList();
    },
    getnewsList() {
      dynamicList({ pageNum: this.pageNum, pageSize: this.pageSize, status: 1 }).then(
        (res) => {
          this.newsList = [...this.newsList, ...res.rows];
          this.total = res.total;
          this.pageNum++; // 递增页数放在请求完成后
          this.loading = false; // 请求完成后设置loading为false

          if (this.newsList.length >= this.total) {
            this.finished = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.newsUpdate {
  // overflow-y: scroll;
  // max-height: 350px;
  border-radius: 10px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(232, 239, 251, 0.6) 100%);
  box-shadow: 3px 3px 2px 0px rgba(255, 255, 255, 0.7) inset,
    -2px -2px 4px 0px rgba(207, 220, 240, 0.8) inset,
    2px 1px 17px 0px rgba(154, 169, 194, 0.3);
  padding: 20px 12px;
  margin: 0 12px;
  margin-bottom: 40px;

  .newsList {
    border-radius: 8px;
    background: #e6ebf5;
    padding: 14px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 2px 2px 15px 0px rgba(165, 180, 206, 0.4) inset,
      -4px 0px 8px 0px rgba(255, 255, 255, 0.7) inset;

    .news-title {
      color: #373737;
      font-family: PingFangSCBold;
      font-size: 14px;
      line-height: 140%;
    }

    .news-content {
      margin: 7px 0 12px;
      color: rgba(55, 55, 55, 0.6);
      font-family: PingFangSCMedium;
      font-size: 12px;
      line-height: 140%;
      display: -webkit-box;
      /* 将元素设置为弹性盒子布局 */
      -webkit-box-orient: vertical;
      /* 设置弹性盒子的排列方向为垂直 */
      overflow: hidden;
      /* 隐藏溢出部分的内容 */
      -webkit-line-clamp: 3;
      /* 设置最大显示行数为3行 */
    }

    .news-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .news-time {
        text-shadow: 1px 2px 2px rgba(120, 139, 172, 0.3);
        font-family: PingFangSCBold;
        font-size: 16px;
        color: #ffffff;
      }

      img {
        width: 53px;
        height: 21.335px;
      }
    }
  }

  .newsList:first-child {
    margin-top: 0;
  }

  .newsList-en {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ::v-deep .van-cell__value {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .news-content {
      width: 200px;

      .news-title {
        font-family: Poppins-Medium;
        // css溢出2行显示...
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .news-time {
        font-family: PingFang SC-Bold;
        font-size: 12px;
        margin-top: 15px;
      }
    }

    .news-bottom {
      width: 89px;
      height: 89px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
</style>