<template>
    <!-- 组织结构 -->
    <div class="organizational">
        <div class="contact-us-banner">
            <div class="contact_banner_wrap">
                <swiper :options="jobOption" class="swiper-container" ref="jobSwiper" v-if="contentsList.length > 1"
                    @slideChangeTransitionEnd="slideChangeTransitionEnd">
                    <swiper-slide class="swiper-slide" v-for="(item, index) in contentsList" :key="index">
                        <div class="swiper-contents">
                            <img :src="$config.proxy + item.picLogo" alt="" class="logopic">
                            <img :src="$config.proxy + item.picMap" alt="" class="mappic">
                            <div class="content-title" :class="$i18n.locale != 'en' ? '' : 'en-font-family-Medium'">{{
                    item.name }}</div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="mennu">
            <img :src="$config.proxy + picCorporation" />
            <div class="mennu-content">
                <div class="contentList" v-for="(item, index) in contentList" :key="index">
                    <img :src="item.icon" class="contenticon">
                    <div class="contentName" :class="$i18n.locale != 'en' ? '' : 'en-font-family-Regular'">{{ item.name
                        }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <Statistics></Statistics> -->


        <div class="contact-us-bottom" :class="$i18n.locale != 'en' ? '' : 'contact-us-bottom-en'"
            v-if="emailList.length > 0">
            <img class="contact-us-bottom-pic" :src="$config.proxy + emailList[0].pic" />
            <div class="bottom-block" v-if="$i18n.locale != 'en'">
                <span class="contact-email">{{ $t("contact.joinUs.jlyx") }}:{{ emailList[0].email }}</span>
                <span class="contact-notes">{{ emailList[0].text }}</span>
            </div>
            <div class="bottom-block" v-if="$i18n.locale == 'en'">
                <span class="contact-email contact-email-en">{{ $t("contact.joinUs.jlyx") }}:<br /><span
                        class="email-style-en">{{ emailList[0].email }}</span></span>
            </div>
        </div>
    </div>
</template>

<script>

import Statistics from "@/components/Statistics.vue"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { organizationalList } from "@/api/home/index";
import { emailList } from "@/api/home/index";
import 'swiper/dist/css/swiper.css';
export default {
    components: {
        swiper,
        swiperSlide,
        Statistics
    },
    data() {
        return {
            emailList: [],
            jobOption: {
                centeredSlides: true,
                spaceBetween: 20,
                slidesPerView: 2,
                loop: true,
                slideToClickedSlide: true,
            },
            picCorporation: '',
            contentsList: [],
            contentList: [
                {
                    icon: require('@/assets/images/contact/photo.png'),
                    name: '',
                },
                {
                    icon: require('@/assets/images/contact/emailicon.png'),
                    name: '',
                },
                {
                    icon: require('@/assets/images/contact/position.png'),
                    name: '',
                },
            ]
        }

    },
    created() {
        this.getemailList();
        this.getOrganizational();
    },
    methods: {
        getemailList() {
            emailList({ pageNum: 1, pageSize: 1, status: "1" }).then((res) => {
                this.emailList = res.rows;
            });
        },
        slideChangeTransitionEnd() {
            let showJobInfo = this.contentsList[this.$refs.jobSwiper.swiper.realIndex]
            this.contentList[0].name = showJobInfo.contact
            this.contentList[1].name = showJobInfo.mail
            this.contentList[2].name = showJobInfo.address
            this.picCorporation = showJobInfo.picCorporation
        },
        getOrganizational() {
            organizationalList({ pageNum: 1, pageSize: 9999, status: "1" }).then(
                (res) => {
                    this.contentsList = res.rows
                    this.picCorporation = res.rows[0].picCorporation
                    let showJobInfo = this.contentsList[0]
                    this.contentList[0].name = showJobInfo.contact
                    this.contentList[1].name = showJobInfo.mail
                    this.contentList[2].name = showJobInfo.address
                }
            );
        },
    },
}
</script>

<style lang="scss" scoped>
.organizational {
    .contact-us-banner {
        height: 220px;

        .swiper-container {
            width: 100%;
            height: 220px;
            border-radius: 10px;
            background: #f1f3f8;

            .swiper-wrapper {

                .swiper-slide {
                    // margin: 0;
                    padding: 18px 18px 22px 18px;
                    box-sizing: border-box;
                    width: 176px;
                    height: 212px;
                    border-radius: 10px;
                    background: linear-gradient(180deg, rgba(242, 247, 255, 0.60) 0%, rgba(215, 224, 239, 0.60) 100%);
                    box-shadow: 2px 3px 2px 0px rgba(255, 255, 255, 0.70) inset, -1px -1px 4px 0px rgba(207, 220, 240, 0.80) inset, 3px 2px 10px 0px rgba(154, 169, 194, 0.30);
                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px);

                    .swiper-contents {

                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        align-items: center;



                        .logopic {
                            width: 94px;
                            align-self: start;
                        }

                        .mappic {
                            width: 140px;
                            height: 140px;
                            fill: #E6EBF5;
                            // margin: 13px 0 15px 0;

                        }

                        .content-title {
                            color: #262D37;
                            text-align: center;
                            font-family: PingFangSCBold;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }



                }

                .swiper-slide-next,
                .swiper-slide-prev {
                    margin: 17px 0;
                    margin-top: 17px;
                    padding: 15px 15px 18px 15px;
                    box-sizing: border-box;
                    width: 147px;
                    height: 178px;
                    border-radius: 10px;
                    background: linear-gradient(180deg, rgba(242, 247, 255, 0.60) 0%, rgba(215, 224, 239, 0.60) 100%);
                    box-shadow: 2px 3px 2px 0px rgba(255, 255, 255, 0.70) inset, -1px -1px 4px 0px rgba(207, 220, 240, 0.80) inset, 3px 2px 10px 0px rgba(154, 169, 194, 0.30);
                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px);

                    .logopic {
                        width: 80px;

                    }

                    .swiper-contents .mappic {
                        width: 94px;
                        height: 94px;
                        margin: 11px 0 12px 0;
                    }

                    .content-title {
                        font-size: 12px;
                        line-height: 17px;
                    }
                }

            }
        }
    }


    .mennu {
        margin: 20px 12px 25px 12px;
        width: 351px;
        padding: 12px 12px;
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.50);

        img {
            width: 328px;
            height: 142px;
            border-radius: 4px;


        }

        .mennu-content {
            display: flex;
            flex-direction: column;
            // align-items: center;
            // flex-wrap: wrap;
            margin-top: 20px;

            .contentList {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .contenticon {
                    width: 18.644px;
                    height: 18.983px;
                    margin-right: 14px;
                }

                .contentName {
                    color: #373737;
                    font-family: 'PingFangSCMedium';
                    font-size: 14px;
                    line-height: 20px;
                }
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    width: 100%;
                    align-items: flex-start;


                    .contenticon {
                        margin-top: 3px;
                    }
                }
            }

            // .contentList:nth-child(1),
            // .contentList:nth-child(2) {
            //     width: 50%;
            // }

        }

    }

}

// 英文的字体
.en-font-family-Medium {
    font-family: Poppins-Medium !important;

}

.en-font-family-Regular {
    font-family: Poppins-Regular !important;
}

.contact-us-bottom {
    padding: 16px 15px 0px 12px;
    box-sizing: border-box;
    width: 100%;
    // height: 87px;
    background: #f4f9ff;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .contact-us-bottom-pic {
        background-size: contain;
        width: 80px;
        height: 71px;
        align-self: flex-end;
    }

    .bottom-block {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        align-self: center;

        .contact-email {
            font-family: PingFangSCHeavy;
            font-size: 14px;
            color: #262d37;
            line-height: 20px;
        }

        .contact-email-en {
            font-family: Poppins-Regular;
            font-weight: 400;
            color: #373737;
        }

        .email-style-en {
            font-family: Poppins-Medium;
            color: #373737;
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
        }

        .contact-notes {
            color: rgba(55, 55, 55, 0.6);
            font-family: PingFangSCBold;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin: 9px 0;
        }
    }

}

.contact-us-bottom-en {
    padding-top: 13px;
}
</style>