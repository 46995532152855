import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter)//使用插件

// 在路由内重写push或replace方法（解决控制台报错）
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location){
  return originPush.call(this,location).catch(err=>err)
}
VueRouter.prototype.replace = function push(location){
  return originReplace.call(this,location).catch(err=>err)
}
import Home from '@/pages/Home' //引入路由组件
import About from '@/pages/About'
import Product from '@/pages/Product'
import ProductDetail from '@/pages/Product/details.vue'
import News from '@/pages/News/index.vue'
import NewsDetail from '@/pages/News/component/NewsDetails.vue'
import Contact from '@/pages/Contact'
import Tools from '@/pages/Tools'
import Test from '@/pages/Test'

//配置路由
export default new VueRouter({
    routes: [
        { path: '/', component: Home },
        { path: '/about', component: About },
        { path: '/product', name: 'Product', component: Product , props: true},
        { path: '/productDetail', name: 'ProductDetail', component: ProductDetail },
        { path: '/news', name: 'News', component: News },
        { path: '/newsDetail', name: 'NewsDetail', component: NewsDetail },
        { path: '/contact', component: Contact },
        { path: '/tools', component: Tools },
        { path: '/test', component: Test },

    ],
    // 回到页面顶部
    scrollBehavior() {
        //x代表横坐标，y代表纵坐标
        return { x: 0,y: 0 };
    }
})