<template>
  <div :class="['company-intro', $i18n.locale == 'en' ? 'company-intro-en' : '']">
    <div class="title">{{ companyList[0]?.name }}</div>
    <div class="content" v-html="companyList[0]?.context"></div>
  </div>
</template>

<script>
import { companyList } from "@/api/home";
export default {
  data() {
    return {
      companyList: [],
    };
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo() {
      companyList({ pageNum: 1, pageSize: 1, type: "1" }).then((res) => {
        this.companyList = res.rows;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 公司简介
.company-intro {
  padding: 15px 15px 20px 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 12px;

  .title {
    color: #373737;
    font-family: PingFangSCBold;
    font-size: 16px;
    line-height: 217.5%;
  }

  .content {
    color: rgba(55, 55, 55, 0.8);
    font-family: PingFangSCMedium;
    font-size: 14px;
    line-height: 189.5%;
    ::v-deep h3 {
    }
  }
}
.company-intro-en {
  .title {
    font-family: Poppins-Medium;
  }
  .content {
    font-family: Poppins-Regular;
  }
}
</style>