<template>
  <div class="teamStyle">
    <img v-for="(item,index) in teamStyleList" :key="index" :src="$config.proxy+item.appPic" alt="" />
  </div>
</template>

<script>
import { mienList } from "@/api/home";
export default {
  data() {
    return {
        teamStyleList:[]
    };
  },
  created() {
    this.getTeamPic()
  },
  methods: {
    getTeamPic(){
        mienList({pageNum:1,pageSize:9999}).then(res=>{
            let data=res.rows
            this.teamStyleList=data
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.teamStyle {
  padding: 20px 12px 0 12px;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 496px;
  }
}
</style>