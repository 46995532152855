<template>
    <!-- 特色产品 -->
    <div :class="['characteristicProducts', $i18n.locale == 'en' ? 'characteristicProducts-en' : '']">
        <div class="titleList">
            <div class="titleCard" v-for="(item, index) in productTypeList" :key="index" @click="change(item, index)">
                <div :class="[active == index ? 'activeName' : 'name']">{{ $i18n.locale != 'en' ? item.dictLabel :
        item.remark }}</div>
                <div :class="['line', active == index ? 'activeLine' : '']"></div>
            </div>
        </div>
        <div class="product">
            <div class="productList" v-for="(item, index) in productList" :key="index" @click="jump(item)">
                <div class="productlist-img">
                    <img :src="$config.proxy + item.picBg">
                </div>
                <div class="productCard">
                    <div class="productCard-title">【{{ item.name }}】</div>
                    <div class="productCard-content">{{ item.context }}</div>
                </div>
            </div>
            <div v-if="productList.length == 0" style="display: flex;
            justify-content: center; align-items: center; width: 100%;">
                {{ $i18n.locale == 'en' ? 'Coming soon! Please expecting!' : '完善中，敬请期待!' }}
            </div>
        </div>
    </div>
</template>

<script>
import { productList, } from '@/api/home/index'
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
    data() {
        return {
            productTypeList: [
                {
                    dictLabel: '产品中心',
                    remark: 'Products'
                }, {
                    dictLabel: '解决方案',
                    remark: 'Solutions'
                }
            ],
            active: null,
            titleList: [
                {
                    name: '国内项目',
                }, {
                    name: '外资合营项目'
                }, {
                    name: '海外项目',
                }
            ],
            productList: []
        }
    },
    methods: {
        getProductList(index) {
            productList({ pageNum: 1, pageSize: 9999, type: index, status: 1 }).then((res) => {
                this.productList = res.rows
            })
        },
        change(item, index) {
            this.active = index
            // 然后修改产品列表
            this.getProductList(index+1)
        },
        jump(item) {
            // console.log(item);
            if (item.detailContent) {
                this.$router.replace({
                    path: '/productDetail',
                    query: {
                        id: item.id,
                        // item: JSON.stringify(item)
                    }
                })    
            } else {
                // 提示没有详情
                Toast(this.$i18n.locale == 'en' ? 'No details' : '该产品暂无详情');
            }  
        }
    },
    created() {
        this.active = 0;
        this.getProductList(1)


    },
}
</script>

<style lang="scss" scoped>
/* 隐藏滚动条 */
::-webkit-scrollbar {
    display: none;
    /* 隐藏 Chrome 和 Safari 中的滚动条 */
}

.characteristicProducts {
    margin: 0 12px;
    padding: 15px 0;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    overflow: hidden;

    .titleList {
        display: flex;
        align-items: center;
        margin-left: 15px;
        width: 100%;
        overflow: auto;

        .titleCard {
            margin-right: 20px;

            .name {
                color: rgba(55, 55, 55, 0.80);
                font-family: PingFangSCBold;
                font-size: 14px;
                white-space: nowrap;
            }

            .activeName {
                color: #373737;
                font-family: PingFangSCBold;
                font-size: 16px;
                white-space: nowrap;
            }

            .line {
                margin-top: 5px;
                width: 60%;
                height: 3px;
                border-radius: 1px;
                background: #2A7EFF;
                opacity: 0;
            }

            .activeLine {
                opacity: 1;
            }
        }
    }

    .product {
        display: flex;
        width: 100%;
        margin-top: 20px;
        overflow-x: auto;
        overflow-y: hidden;

        .productList {
            position: relative;
            margin-right: 10px;

            .productlist-img {
                width: 284px;
                height: 177px;
                // background: linear-gradient(180deg, rgba(25, 36, 53, 0.15) 0%, rgba(25, 36, 53, 0.75) 100%);
                border-radius: 10px;
                // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
                // background: linear-gradient(180deg, #DDDDDD 0%, #1D1D1D 100%);
                background: rgba(0, 0, 0, 0.7);

                img {
                    opacity: 0.3;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .productCard {
                position: absolute;
                color: #fff;
                opacity: 1;
                bottom: 13px;
                left: 13px;

                .productCard-title {
                    font-family: PingFangSCBold;
                    font-size: 16px;
                }

                .productCard-content {
                    display: -webkit-box;
                    /* 将元素设置为弹性盒子布局 */
                    -webkit-box-orient: vertical;
                    /* 设置弹性盒子的排列方向为垂直 */
                    overflow: hidden;
                    /* 隐藏溢出部分的内容 */
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数为3行 */
                    font-family: PingFangSCMedium;
                    font-size: 14px;
                    line-height: 1.5;
                    margin-top: 10px;
                }
            }
        }

        .productList:first-child {
            margin-left: 15px;
        }

        .productList:last-child {
            margin-right: 15px;
        }
    }

    .product::-webkit-scrollbar {
        height: 0;
        /* 隐藏滚动条 */
    }
}

.characteristicProducts-en {
    .titleList {
        .titleCard {
            .name {
                font-family: Poppins-Medium;
            }

            .activeName {
                font-family: Poppins-Medium;
            }
        }
    }

    .product {
        .productList {
            .productCard {
                .productCard-title {
                    font-family: Poppins-Medium;
                }

                .productCard-content {
                    font-family: Poppins-Regular;
                }
            }
        }
    }
}
</style>