<template>
    <div :class="['companyNews',$i18n.locale=='en'?'companyNews-en':'']">
        <div class="list" v-for="(item, index) in dynamicList" :key="index" @click="jump(item)">
            <div class="top">
                <div class="left">
                    <div class="title">{{ item.title }}</div>
                    <div class="content">{{ item.date }}</div>
                </div>
                <div class="right">
                    <img :src="$config.proxy + item.pic" alt="">
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="more">
            <ButtonCard :ButtonInfo="ButtonInfo"></ButtonCard>
        </div>
    </div>
</template>

<script>
import ButtonCard from "@/components/ButtonCard.vue";

export default {
    data() {
        return {
            ButtonInfo: {
                path: '/news'
            }
        }
    },
    components: {
        ButtonCard,
    },
    props: {
        dynamicList: {
            type: Array,
            required: false
        },
    },
    methods: {
        jump(item) {
            this.$router.push({
                name: "NewsDetail",
                params: {
                    id: item.id,
                },
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.companyNews {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    margin: 0 12px;
    padding: 25px 15px 20px 15px;
    box-sizing: border-box;

    .list {
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                width: 209px;
                font-family: PingFangSCBold;

                .title {
                    color: #373737;
                    font-size: 14px;
                    line-height: 166%;
                    display: -webkit-box;
                    /* 将元素设置为弹性盒子布局 */
                    -webkit-box-orient: vertical;
                    /* 设置弹性盒子的排列方向为垂直 */
                    overflow: hidden;
                    /* 隐藏溢出部分的内容 */
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数为2行 */
                }

                .content {
                    color: rgba(55, 55, 55, 0.80);
                    font-size: 12px;
                    line-height: 189.5%;
                }

            }

            .right {
                flex-shrink: 0;
                width: 89px;
                height: 89px;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
        }

        .line {
            width: 209px;
            height: 1px;
            background: rgba(55, 55, 55, 0.20);
            margin: 15px 0;
        }
    }

    .list:nth-last-child(2) .line {
        display: none;
    }

    .more {
        margin-top: 10px;
    }
}
.companyNews-en {
    .list {
        .top {
            .left {
                font-family: Poppins-Medium;
            }
        }
    }
}
</style>