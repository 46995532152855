var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['philosophy', _vm.$i18n.locale == 'en' ? 'philosophy-en' : '']},_vm._l((_vm.teamList),function(item,index){return _c('div',{key:index,staticClass:"teamList"},[_c('div',{staticClass:"itemTop"},[_c('img',{staticClass:"titleIcon",attrs:{"src":require("@/assets/images/about/titleIcon.png")}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"itemBottom"},[_c('div',{class:[
  item.title == '管理理念' || item.title == 'Management philosophy'
    ? 'glln'
    : item.title == '经营理念' || item.title == '人才理念' || item.title == 'Talent concept' || item.title == 'Business philosophy'
      ? 'jyrcln'
      : item.title == '服务理念' || item.title == 'Service philosophy'
        ? 'fwln'
        : '', _vm.$i18n.locale == 'en' ? 'itemBottom-en' : ''
]},[_c('div',{class:['context', _vm.$i18n.locale == 'en' ? 'context1' : ''],domProps:{"innerHTML":_vm._s(item.context)}})])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }