<template>
  <div id="app">
    <TopBar></TopBar>
    <router-view></router-view>
    <BottonBar v-if="$route.path != '/test'"></BottonBar>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import BottonBar from './components/BottomBar.vue'
import { getCache } from '@/utils/index'

export default {
  name: 'App',
  components: {
    TopBar,
    BottonBar
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['mobile', 'android', 'iphone', 'ipad', 'windows phone'];
      for (let keyword of mobileKeywords) {
        if (userAgent.indexOf(keyword) !== -1) {
          return true;
        }
      }
      return false;
    }
  },
  created() {
    // console.log(window.screen.width);
    console.log(navigator.userAgent.toLowerCase());
    // if (window.screen.width > 900) {
    //   //  window.location.href = 'http://111.53.214.119:8903' // 测试pc地址
    //   window.location.href = 'https://etres-ty.com' // 正式pc地址
    // }
    console.log(this.isMobile());
    if (!this.isMobile()) {
      console.log('当前设备是pc');
      //  window.location.href = 'http://111.53.214.119:8903' // 测试pc地址
      window.location.href = 'https://etres-ty.com' // 正式pc地址
    }
    this.$i18n.locale = getCache('lang') ? getCache('lang') : 'zh'
  },
}
</script>

<style lang="scss">
#app {
  background: #F1F3F8;
}

@font-face {
  font-family: 'PingFangSCHeavy';
  src: url('./assets/font/PingFang Heavy_1.ttf');
}

@font-face {
  font-family: 'PingFangSCBold';
  src: url('./assets/font/PingFang\ Bold_0.ttf');
}

@font-face {
  font-family: 'PingFangSCMedium';
  src: url('./assets/font/PingFang\ Medium_1.ttf');
}

@font-face {
  font-family: 'PingFangSCRegular';
  src: url('./assets/font/PingFang\ Regular_1.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/font/Poppins-Bold.otf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/font/Poppins-SemiBold.otf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/font/Poppins-Medium.otf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/font/Poppins-Regular.otf');
}
</style>
