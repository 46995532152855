<template>
    <div class="test">
        <van-field v-model="value1" right-icon="search" placeholder="请输入关键词" />
        <div class="buttonlist">
            <div class="button1" @click="show = true">提交日期<van-icon name="arrow-down" color="#505764" /></div>
            <div class="button2" @click="showPicker = true">审核状态<van-icon name="arrow-down" color="#505764" /></div>
        </div>
        <div class="cardlist" v-for="(item, index) in cardList " :key="index">
            <div class="card">
                <div class="top-card">
                    <div class="info">
                        <div class="name">{{ item.name }}</div>
                        <div class="line">|</div>
                        <div class="model">{{ item.model }}</div>
                    </div>
                    <div class="time">{{ item.time }}</div>
                </div>
                <div class="bottom-card">
                    <div class="num">{{ item.num }}</div>
                    <div :class="['state', item.state == 1 ? 'state1' : item.state == 2 ? 'state2' : '']">
                        {{ item.state == 1 ? '未通过' : item.state == 2 ? '待审核' : '已通过' }}
                    </div>
                </div>
            </div>
        </div>




        <van-calendar v-model="show" type="range" color="#1989fa" @confirm="onConfirm" />
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm1" @cancel="showPicker = false" />
        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value1: '',
            date: '',
            state: '',
            show: false,
            showPicker: false,
            columns: ['已通过', '未通过', '待审核'],
            cardList: [{
                name: 'Type-C扩展坞',
                model: '联想/mk-uuu',
                time: '2023-12-06',
                num: 'ETR-20231206-b75d2f9ff3',
                state: 1
            }, {
                name: 'Type-C扩展坞',
                model: '联想/mk-uuu',
                time: '2023-12-06',
                num: 'ETR-20231206-b75d2f9ff3',
                state: 2
            }, {
                name: 'Type-C扩展坞',
                model: '联想/mk-uuu',
                time: '2023-12-06',
                num: 'ETR-20231206-b75d2f9ff3',
                state: 3
            }, {
                name: 'Type-C扩展坞',
                model: '联想/mk-uuu',
                time: '2023-12-06',
                num: 'ETR-20231206-b75d2f9ff3',
                state: 3
            },
            ]
        }
    },
    methods: {
        formatDate(date) {
            return `${date.getMonth() + 1}/${date.getDate()}`;
        },
        onConfirm(date) {
            const [start, end] = date;
            this.show = false;
            this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
        },
        onConfirm1(value) {
            this.state = value;
            this.showPicker = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.test {
    min-height: 100vh;
    background: #F6F9FF;
    padding: 55px 10px 0 10px;
    box-sizing: border-box;

    .buttonlist {
        display: flex;
        font-size: 14px;
        color: #505764;
        margin: 16px 0;

        .button1 {
            margin-right: 30px;
        }
    }

    .cardlist {

        .card {
            border-radius: 4px;
            background: #FFFFFF;
            padding: 15px;
            margin-bottom: 10px;

            .top-card {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info {
                    display: flex;
                    align-items: center;

                    .name {
                        font-size: 16px;
                        color: #505764;
                        line-height: 23px;
                    }

                    .line {
                        line-height: 23px;
                        font-size: 14px;
                        color: #505764;
                        margin: 0 8px;
                    }

                    .model {
                        color: rgba(80, 87, 100, 0.5);
                        font-size: 14px;
                    }
                }

                .time {
                    color: #505764;
                    font-size: 12px;
                }

            }

            .bottom-card {
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;

                .num {
                    color: #505764;

                }

                .state {
                    color: #5DC42B;
                    padding: 2px 6px;
                    line-height: 17px;
                    box-sizing: border-box;
                    border: 0.8px solid #5DC42B;
                    border-radius: 3px;
                }
                .state1{
                    color:#F13A3A;
                    border: 0.8px solid #F13A3A;
                }
                .state2{
                    color:#3C9CFF;
                    border: 0.8px solid #3C9CFF;
                }
            }
        }

    }
}
</style>