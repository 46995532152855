<template>
    <div :class="['company-intro', $i18n.locale == 'en' ? 'company-intro-en' : '']">
        <div class="title">{{ companyInfo[0]?.name }}</div>
        <div class="content" v-html="companyInfo[0]?.context"></div>
        <div class="more">
            <ButtonCard></ButtonCard>
        </div>
    </div>
</template>

<script>
import ButtonCard from '@/components/ButtonCard.vue'
export default {
    components: {
        ButtonCard,
    },
    props: {
        companyInfo: {
            type: Array,
            required: false
        },
    },
}
</script>

<style lang="scss" scoped>
// 公司简介
.company-intro {
    padding: 15px 15px 20px 15px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    margin: 0 12px;

    .title {
        color: #373737;
        font-family: PingFangSCBold;
        font-size: 16px;
        line-height: 217.5%;
    }

    .content {
        color: rgba(55, 55, 55, 0.80);
        font-family: PingFangSCMedium;
        font-size: 14px;
        line-height: 189.5%;
        height: 186px !important;
        display: -webkit-box;
        /* 将元素设置为弹性盒子布局 */
        -webkit-box-orient: vertical;
        /* 设置弹性盒子的排列方向为垂直 */
        overflow: hidden;
        /* 隐藏溢出部分的内容 */
        -webkit-line-clamp: 7;
        /* 设置最大显示行数为3行 */
        text-overflow: ellipsis;
    }

    .more {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }
}

.company-intro-en {
    ::v-deep p {
        word-break: keep-all !important;
    }

    .title {
        font-family: Poppins-Medium;
    }

    .content {
        font-family: Poppins-Regular;
        text-align: justify;
    }
}
</style>