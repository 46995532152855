<template>
  <div class="topbar">
    <div class="top">
      <img class="menu" src="@/assets/images/home/menu.png" @click="show = true" />
      <div style="display: flex; align-items: center;">
        <img class="logo" src="@/assets/images/home/logo.png" />
        <div class="lang" v-if="$route.path != '/newsDetail' && $route.path != '/productDetail'" @click="changeLang" style="background: #2a7eff;">
          {{ $t('lang.name') }}</div>
      </div>
    </div>
    <van-popup v-model="show" position="left" :style="{ width: '60%', height: '100%' }">
      <van-sidebar v-model="activeKey" style="width: 100%" @change="jumpTo">
        <van-sidebar-item v-for="(item, index) in tabLists" :key="index" @click="change(item)">
          <template #title>
            <div>
              {{ item.name }}
              <van-icon v-if="(item.id == 2 && !childrenShow) || (item.id == 3 && !childrenShow1)" name="arrow" />
              <van-icon v-if="(item.id == 2 && childrenShow) || (item.id == 3 && childrenShow1)" name="arrow-down" />

            </div>
            <div class="" v-if="item.id == 2 && childrenShow">
              <div v-for="(item, index) in productTypeList" :key="index"
                :class="[productIndex == index ? 'active-children' : 'children']"
                style="margin-top: 20px; margin-left: 10px;" @click.stop="jump(item, index, 1)">
                {{ $i18n.locale == 'en' ? item.remark : item.dictLabel }}
              </div>
            </div>
            <div class="" v-if="item.id == 3 && childrenShow1">
              <div v-for="(item, index) in productTypeList1" :key="index"
                :class="[productIndex1 == index ? 'active-children' : 'children']"
                style="margin-top: 20px; margin-left: 10px;" @click.stop="jump(item, index, 2)">
                {{ $i18n.locale == 'en' ? item.remark : item.dictLabel }}
              </div>
            </div>
          </template>
        </van-sidebar-item>
      </van-sidebar>
    </van-popup>
  </div>
</template>

<script>
import { projectTypeList } from '@/api/home/index'
import { setCath, getCache } from '@/utils/index'
export default {
  data() {
    return {
      show: false,
      activeKey: 0,
      tabLists: [
        { id: 0, name: this.$t('menu.name1'), path: "/" },
        { id: 1, name: this.$t('menu.name2'), path: "/about" },
        { id: 2, name: this.$t('menu.name3')},
        { id: 3, name: this.$t('menu.name4')},
        { id: 4, name: this.$t('menu.name5'), path: "/news" },
        { id: 5, name: this.$t('menu.name6'), path: "/contact" },
      ],
      childrenShow: false,
      productIndex: null,
      productTypeList: [],
      childrenShow1: false,
      productIndex1: null,
      productTypeList1: []
    };
  },
  // 监听路由的变化
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route && this.$route.path) {
          let found = false;
          this.tabLists.forEach((item) => {
            if (item.path == this.$route.path) {
              this.activeKey = item.id;
              found = true;
            }
          });
          if (!found) {
            this.activeKey = -1; // 或者其他默认值
          }
        }
      },
    },
  },
  methods: {
    changeLang() {
      // console.log(getCache('lang'));
      if (getCache('lang') === 'en') {
        setCath('lang', 'zh')
        this.$i18n.locale = 'zh'
      } else {
        setCath('lang', 'en')
        this.$i18n.locale = 'en'
      }
      // window.location.href = "http://192.168.1.98:8080"
      window.location.reload();
    },
    jumpTo() {
      if (this.activeKey >= 0 && this.activeKey != 2 && this.activeKey != 3) {
        this.show = false;
        this.childrenShow = false;
        this.childrenShow1 = false;
        this.productIndex = null
        this.productIndex1 = null
        this.$router.replace(this.tabLists[this.activeKey].path);
      } else if (this.activeKey == 2) {
        this.childrenShow = true;
        this.childrenShow1 = false
      } else if (this.activeKey == 3) {
        this.childrenShow1 = true;
        this.childrenShow = false
      }
    },
    change(item) {
      if (item.id == 2) {
        this.childrenShow = !this.childrenShow;
      }
      if (item.id == 3) {
        this.childrenShow1 = !this.childrenShow1;
      }

    },
    jump(item, index, type) {
      this.show = false;

      if (type == 1) {
        this.productIndex = index
        this.productIndex1 = null
        this.childrenShow1 = false
      }
      if (type == 2) {
        this.productIndex1 = index
        this.productIndex = null
        this.childrenShow = false
      }
      
      this.$router.replace({
        path: '/product',
        query: {
          dictValue: item.dictValue,
          dictLabel: item.dictLabel,
          remark: item.remark,
          type: type
        }
      })
    }
  },
  created() {
    projectTypeList().then(res => {
      this.productTypeList = res.rows[0].dictType == "dict_project_type" ? res.rows[0].dictData : res.data.rows[1].dictData
      this.productTypeList1 = res.rows[0].dictType == "dict_project_type" ? res.rows[1].dictData : res.data.rows[0].dictData
    })
  }
};
</script>
<style scoped>
::v-deep .van-sidebar-item--select::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.10667rem;
  height: 0.42667rem;
  background-color: #2a7eff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
}

::v-deep .van-sidebar-item {
  padding-left: 20px;
}

::v-deep .van-popup {
  background-color: #f7f8fa;
}

::v-deep .van-sidebar-item--select::before {
  top: 30px;
}
</style>
<style lang="scss" scoped>
.topbar {
  position: fixed;
  top: 0;
  font-size: 20px;
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(133, 165, 218, 0.15);
  z-index: 999;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    box-sizing: border-box;

    .menu {
      width: 23px;
      height: 23px;
    }

    .logo {
      width: 119px;
      height: 16.63px;
    }

    .lang {
      width: 30px;
      text-align: center;
      margin-left: 10px;
      box-sizing: border-box;
      padding: 2px;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      font-family: PingFangSCBold;
    }
  }

  .active-children {
    color: #2A7EFF;
  }
}
</style>