<template>
    <div class="container">
        <van-swipe class="my-swipe" :autoplay="3000">
            <!-- 广告banner开始 -->
            <van-swipe-item>
                <img class="home-banner-img" src="@/assets/images/home/home_banner_bg.png" alt="">
                <div class="home-banner-zh" style="background: none;" v-if="$i18n.locale != 'en'">
                    <span class="banner-text">
                        {{ $t('home.title1') }}
                        <span class="special-text">
                            {{ $t('home.title2') }}
                        </span>
                    </span>
                    <span class="banner-text1">{{ $t('home.title3') }}</span>
                    <div class="text-en"><span>{{ $t('home.subTitle') }}</span></div>
                    <van-button class="van-button" @click="openChat">{{ $t('home.button') }}</van-button>
                </div>
                <div class="home-banner-en" v-if="$i18n.locale == 'en'">
                    <span class="banner-text">
                        {{ $t('home.title1') }}
                    </span>
                    <span class="banner-text1">
                        {{ $t('home.title2') }}
                        <span class="special-text">
                            {{ $t('home.title3') }}
                        </span>
                    </span>
                    <div class="text-en1"><span>{{ $t('home.subTitle') }}</span></div>
                    <van-button class="van-button" @click="openChat">{{ $t('home.button') }}</van-button>
                </div>
            </van-swipe-item>
            <van-swipe-item v-for="(item,index) in $t('home.bannerList')" :key="index">
                <img class="home-banner-img" :src="item.url" alt="">
                <div :class="['home-banner1', $i18n.locale == 'en' ? 'home-banner1-en' : '']">
                    <span class="banner-text">{{ item.title1 }}</span>
                    <span class="text-en">{{ item.title2 }}</span>
                    <van-button class="van-button" @click="openChat">{{ $t('home.button') }}</van-button>
                </div>
            </van-swipe-item>
        </van-swipe>

        <!-- 广告banner结束 -->
        <!-- 公司简介开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo')"></TitleCard>
        <aboutUs :companyInfo="companyInfo"></aboutUs>
        <!-- 公司简介结束 -->
        <!-- 发展团队开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo1')"></TitleCard>
        <developList :teamSmallIconList="teamSmallIconList" :organizationalList="organizationalList"></developList>
        <!-- 发展团队结束 -->
        <!-- 发展历程开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo2')"></TitleCard>
        <developHistory :courseList="courseList"></developHistory>
        <!-- 发展历程结束 -->
        <!-- 核心技术开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo3')"></TitleCard>
        <coreTechnology :technologyInfo="technologyInfo"></coreTechnology>
        <!-- 核心技术结束 -->
        <!-- 公司动态开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo4')"></TitleCard>
        <companyNews :dynamicList="dynamicList"></companyNews>
        <!-- 公司动态结束 -->
        <!-- 特色产品开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo5')"></TitleCard>
        <characteristicProducts></characteristicProducts>
        <!-- 特色产品结束 -->
        <!-- 合作企业开始 -->
        <TitleCard :titleInfo="$t('home.titleInfo6')"></TitleCard>
        <cooperativeEnterprises></cooperativeEnterprises>
        <!-- 合作企业结束 -->
        <!-- <ButtonCard :ButtonInfo="ButtonInfo"></ButtonCard> -->
    </div>
</template>
<script>
import TitleCard from "@/components/TitleCard.vue";
import ButtonCard from "@/components/ButtonCard.vue";
import aboutUs from './component/aboutUs.vue'
import developList from "./component/developList.vue";
import developHistory from "./component/developHistory.vue";
import coreTechnology from "./component/coreTechnology.vue";
import companyNews from "./component/companyNews.vue";
import characteristicProducts from "./component/characteristicProducts.vue";
import cooperativeEnterprises from "./component/cooperativeEnterprises.vue";
import { addStay, companyList, smallIconList, organizationalList, courseList, dynamicList } from "@/api/home/index";
import orderBy from "lodash/orderBy";
export default {
    components: {
        TitleCard,
        ButtonCard,
        aboutUs,
        developList,
        developHistory,
        coreTechnology,
        companyNews,
        characteristicProducts,
        cooperativeEnterprises
    },
    data() {
        return {
            ButtonInfo: {
                path: "/aaa",
            },
            companyInfo: [], // 公司简介
            teamSmallIconList: [], // 发展团队小图标
            organizationalList: [], // 发展团队轮播
            courseList: [], // 发展历程
            technologyInfo: [], // 核心技术
            dynamicList: [], // 公司动态
            stopTime: ''
        };
    },
    methods: {
        init() {
            this.getCompanyList()
            this.getSmallIcon()
            this.getOrganizationalList()
            this.getCourseList()
            this.getDynamicList()
        },
        getCompanyList() {
            companyList().then((res) => {
                companyList({ pageNum: 1, pageSize: 9999, status: "1" }).then((res) => {
                    // 清空
                    this.companyInfo = [];
                    this.teamInfo = [];
                    this.technologyInfo = [];
                    let data = res.rows;
                    data.forEach((item) => {
                        switch (item.type) {
                            case "1":
                                //公司简介
                                this.companyInfo.push(item);
                                break;
                            case "3":
                                //核心技术
                                this.technologyInfo.push(item);
                                break;
                            default:
                                break;
                        }
                    });
                });
            })
        },
        getSmallIcon() {
            smallIconList({ pageNum: 1, pageSize: 4, type: 1 }).then((res) => {
                this.teamSmallIconList = res.rows;//发展团队小图标
                // this.teamSmallIconList[3].title = 'service products'
            });
        },
        getOrganizationalList() {
            organizationalList({ pageNum: 1, pageSize: 9999, status: "1" }).then((res) => {
                this.organizationalList = res.rows;//发展团队轮播
            });
        },
        getCourseList() {
            courseList({ pageNum: 1, pageSize: 99999, status: "1" }).then((res) => {
                let tmpList = res.rows.filter(res=> res.intro.trim() != "").reverse();
                this.courseList = orderBy(tmpList, ["date"], ["desc"]).slice(0,5); //发展历程
                // this.courseList = tmpList.slice(0,5); //发展历程
                console.log(tmpList);
            })
        },
        getDynamicList() {
            dynamicList({ pageNum: 1, pageSize: 4, status: "1", status: 1 }).then((res) => {
                this.dynamicList = res.rows // 公司动态
            })
        },
        //立即咨询
        openChat() {
            window.open('https://work.weixin.qq.com/kfid/kfc1a69b24cfb6ff8cf')
        },
    },
    created() {
        this.stopTime = new Date().getTime()
        this.init()
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 0, time: this.stopTime }).then((res) => {
        })
    },
};
</script>
<style lang="scss" scoped>
$theme-color: #2a7eff;
$bg-color: #f1f3f8;

.container {
    width: 100%;
    font-size: 16px;

    .my-swipe {
        margin-top: 40px;
    }

    //  广告banner
    .home-banner-img {
        width: 100%;
        height: 180px;
    }

    .home-banner-zh,
    .home-banner-en {
        position: absolute;
        top: 29px;
        left: 31px;
        display: flex;
        flex-direction: column;

        .banner-text {
            font-family: PingFangSCHeavy;
            font-size: 14px;
            line-height: 16px;
            color: #2A7EFF;

            .special-text {
                font-family: PingFangSCBold;
                font-size: 10px;
                line-height: 12px;
                color: #404040;
                margin-left: 5px;
            }
        }

        .banner-text1 {
            font-family: PingFangSCHeavy;
            font-size: 22px;
            line-height: 26px;
            color: #262D37;
            margin: 8px 0 1px;
        }

        .text-en {
            width: 130px;
            height: 13px;
            margin-bottom: 22px;
            font-family: PingFangSCMedium;
            font-size: 9px;
            line-height: 11px;
            // text-align: justify;
            display: block;
            letter-spacing: 3px;
            color: #404040;
        }

        .van-button {
            color: #fff;
            font-size: 14px;
            width: 74px;
            height: 28px;
            background: #2a7eff;
            box-shadow: inset -1px -2px 11px rgba(30, 73, 138, 0.7),
                inset 1px 2px 8px rgba(255, 255, 255, 0.68);
            border: 0;
            border-radius: 4px;
            padding: 0;
            text-align: center;
        }
    }

    .home-banner-en {
        top: 39px;

        .banner-text {
            font-family: Poppins-Medium;
            font-size: 12px;
            color: #262D37;
            line-height: 14px;
        }

        .banner-text1 {
            margin: 0;
            font-family: Poppins-Bold;
            font-weight: bold;
            font-size: 22px;
            color: #262D37;
            line-height: 26px;

            .special-text {
                color: #2A7EFF;
            }


        }

        .text-en1 {
            font-family: PingFangSCMedium;
            width: 806px;
            font-size: 9px;
            color: rgba(64, 64, 64, 0.7);
            line-height: 11px;
            margin: 10px 0 13px;
        }
    }
    .home-banner1 {
        position: absolute;
        top: 48px;
        left: 31px;
        display: flex;
        flex-direction: column;

        .banner-text {
            font-family: PingFangSCBold;
            font-size: 22px;
            line-height: 26px;
            color: #ffffff;
        }

        .text-en {
            margin: 7px 0 21px;
            font-family: PingFangSCMedium;
            font-size: 9px;
            line-height: 11px;
            color: #ffffff;
        }

        .van-button {
            color: #fff;
            font-size: 14px;
            width: 74px;
            height: 28px;
            background: #2a7eff;
            box-shadow: inset -1px -2px 11px rgba(30, 73, 138, 0.7),
                inset 1px 2px 8px rgba(255, 255, 255, 0.68);
            border: 0;
            border-radius: 4px;
            padding: 0;
            text-align: center;
        }
    }

    .home-banner1-en {
        top: 41px;

        .banner-text {
            
            font-family: Poppins-SemiBold;
            font-size: 24px;
            line-height: 28px;
        }

        .text-en {
            width: 307px;
            white-space: wrap;
            margin: 2px 0 15px;
            font-family: Poppins-Regular;
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: rgba(255,255,255,0.8);
        }
    }
}
</style>