<template>
  <!-- 加入我们 -->
  <div class="joinUs">
    <div :class="['contact-us-banner', $i18n.locale == 'en' ? 'contact-us-banner-en' : '']">
      <div class="contact_banner_wrap" v-if="jobList.length > 0">
        <swiper :options="$i18n.locale == 'en' ? jobOptionEn : jobOption" class="swiper-container" ref="jobSwiper"
          @slideChangeTransitionEnd="slideChangeTransitionEnd">
          <swiper-slide class="swiper-slide" v-for="(item, index) in jobList" :key="index">
            <div class="swiper-content">
              <div class="job-name">{{ item.position }}</div>
              <div class="job-line"></div>
              <div class="job-title">{{ $t("contact.joinUs.gwyq") }}</div>
              <div class="job-ask" v-html="item.ask"></div>
              <div class="job-contacts">
                {{ $t("contact.joinUs.lxr") }}：{{ item.contacts }}
              </div>
              <div class="job-email">
                {{ $t("contact.joinUs.yx") }}：{{ item.email }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="contact-us-content" v-if="showJobInfo">
      <div class="job-name" :class="$i18n.locale != 'en' ? '' : 'job-name-en'">
        {{ showJobInfo.position }}
      </div>
      <div class="job-content">
        <div class="job-line"></div>
        <div class="job-right">
          <div class="job-title" :class="$i18n.locale != 'en' ? '' : 'job-title-en'">
            <div class="line"></div>
            {{ $t("contact.joinUs.gwzz") }}
          </div>
          <div class="job-context" :class="$i18n.locale != 'en' ? '' : 'job-context-en'" v-html="showJobInfo.ask"></div>
          <div class="job-title" :class="$i18n.locale != 'en' ? '' : 'job-title-en'">
            <div class="line"></div>
            {{ $t("contact.joinUs.gwyq") }}
          </div>
          <div class="job-context" :class="$i18n.locale != 'en' ? '' : 'job-context-en'"
            v-html="showJobInfo.responsibilities"></div>
          <div class="job-title" :class="$i18n.locale != 'en' ? '' : 'job-title-en'">
            <div class="line"></div>
            {{ $t("contact.joinUs.xzdy") }}
          </div>
          <div class="job-context" :class="$i18n.locale != 'en' ? '' : 'job-context-en'" v-html="showJobInfo.salary">
          </div>
        </div>
      </div>
      <div class="job-contacts" :class="$i18n.locale != 'en' ? '' : 'job-contacts-en'">
        <div class="line"></div>
        {{ $t("contact.joinUs.lxr") }}：{{ showJobInfo.contacts }}
      </div>
      <div class="job-email" :class="$i18n.locale != 'en' ? '' : 'job-contacts-en'">
        {{ $t("contact.joinUs.yx") }}：{{ showJobInfo.email }}
      </div>
    </div>
    <div class="contact-us-bottom" :class="$i18n.locale != 'en' ? '' : 'contact-us-bottom-en'" v-if="emailList.length > 0">
      <img class="contact-us-bottom-pic" :src="$config.proxy + emailList[0].pic" />
      <div class="bottom-block" v-if="$i18n.locale != 'en'">
        <span class="contact-email">{{ $t("contact.joinUs.jlyx") }}:{{ emailList[0].email }}</span>
        <span class="contact-notes">{{ emailList[0].text }}</span>
      </div>
      <div class="bottom-block" v-if="$i18n.locale == 'en'">
        <span class="contact-email contact-email-en">{{ $t("contact.joinUs.jlyx") }}:<br /><span
            class="email-style-en">{{ emailList[0].email }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { postList } from "@/api/home/index";
import { emailList } from "@/api/home/index";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.jobSwiper.swiper;
    },
  },
  data() {
    return {
      jobList: [],
      showJobInfo: {},
      jobOption: {
        simulateTouch: false,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 20,
        loopAdditionalSlides: 100,
        slidesPerView: 2,
        loop: true,
        slideToClickedSlide: true,
      },
      jobOptionEn: {
        simulateTouch: false,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 20,
        loopAdditionalSlides: 100,
        slidesPerView: 1.73,
        loop: true,
        slideToClickedSlide: true,
      },
      emailList: [],
    };
  },
  created() {
    this.getpostList();
    this.getemailList();
  },
  methods: {
    slideChangeTransitionEnd() {
      this.showJobInfo = this.jobList[this.$refs.jobSwiper.swiper.realIndex];
    },
    getpostList() {
      postList({ pageNum: 1, pageSize: 9999, status: "1" }).then((res) => {
        this.jobList = res.rows;
        this.showJobInfo = this.jobList[0];
      });
    },
    getemailList() {
      emailList({ pageNum: 1, pageSize: 1, status: "1" }).then((res) => {
        this.emailList = res.rows;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.joinUs {
  .contact-us-banner {
    height: 220px;

    .swiper-container {
      width: 100%;
      height: 212px;
      border-radius: 10px;
      background: #f1f3f8;

      .swiper-wrapper {
        .swiper-slide {
          // margin: 0;
          padding: 14px;
          box-sizing: border-box;
          width: 176px;
          height: 212px;
          border-radius: 10px;
          background: linear-gradient(180deg,
              rgba(242, 247, 255, 0.6) 0%,
              rgba(215, 224, 239, 0.6) 100%);
          box-shadow: 2px 3px 2px 0px rgba(255, 255, 255, 0.7) inset,
            -1px -1px 4px 0px rgba(207, 220, 240, 0.8) inset,
            3px 2px 10px 0px rgba(154, 169, 194, 0.3);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);

          .job-name,
          .job-title {
            color: #262d37;
            font-family: PingFangSCBold;
            font-size: 14px;
          }

          .job-line {
            margin-top: 12px;
            border-bottom: 1px solid rgba(55, 55, 55, 0.2);
          }

          .job-title {
            height: 16px;
            line-height: 16px;
            padding-left: 5px;
            border-left: 2px solid #2a7eff;
            margin-top: 14px;
          }

          .job-ask {
            margin-top: 8px;
            margin-left: 5px;
            color: rgba(55, 55, 55, 0.6);
            font-family: PingFangSCMedium;
            font-size: 12px;
            line-height: 160%;
            height: 76.8px;
            /* 19.2px */
            display: -webkit-box;
            /* 将元素设置为弹性盒子布局 */
            -webkit-box-orient: vertical;
            /* 设置弹性盒子的排列方向为垂直 */
            overflow: hidden;
            /* 隐藏溢出部分的内容 */
            -webkit-line-clamp: 4;
            /* 设置最大显示行数为4行 */
          }

          .job-contacts,
          .job-email {
            margin-left: 5px;
            color: #2a7eff;
            font-family: PingFangSCBold;
            font-size: 12px;
            margin-top: 9px;
            // line-height: 217.5%;
            /* 26.1px */
          }
        }

        .swiper-slide-next,
        .swiper-slide-prev {
          margin: 17px 0;
          margin-top: 17px;
          padding: 8px 12px 14px 12px;
          box-sizing: border-box;
          width: 147px;
          height: 178px;
          border-radius: 10px;
          background: linear-gradient(180deg,
              rgba(242, 247, 255, 0.6) 0%,
              rgba(215, 224, 239, 0.6) 100%);
          box-shadow: 2px 3px 2px 0px rgba(255, 255, 255, 0.7) inset,
            -1px -1px 4px 0px rgba(207, 220, 240, 0.8) inset,
            3px 2px 10px 0px rgba(154, 169, 194, 0.3);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);

          .job-line {
            margin-top: 7.25px;
          }

          .job-title {
            margin-top: 9.5px;
          }

          .job-ask {
            margin-top: 3px;
            height: 57.6px;
            -webkit-line-clamp: 3;
            /* 设置最大显示行数为4行 */
          }
        }

        // .swiper-slide-next {
        //     margin-left: 20px;
        // }
        // .swiper-slide-prev {
        //     margin-right: 20px;
        // }
      }
    }
  }

  .contact-us-banner-en {
    height: 210px !important;

    .swiper-container {
      height: 210px !important;
      .swiper-wrapper {
        .swiper-slide {
          width: 209px !important;
          height: 192px !important;

          .job-name,.job-title {
            font-family: Poppins-Medium;
          }
          .job-ask {
            font-family: Poppins-Regular;
            height: 57.6px;
            /* 19.2px */
            display: -webkit-box;
            /* 将元素设置为弹性盒子布局 */
            -webkit-box-orient: vertical;
            /* 设置弹性盒子的排列方向为垂直 */
            overflow: hidden;
            /* 隐藏溢出部分的内容 */
            -webkit-line-clamp: 3;
            /* 设置最大显示行数为3行 */
          }
          .job-contacts,
          .job-email {
            font-family: Poppins-Regular;
            margin-top: 8px;

          }
        }
        .swiper-slide-next,
        .swiper-slide-prev{
          height: 149px !important;
          .job-ask {
            margin-top: 3px;
            height: 38.4px;
            -webkit-line-clamp: 2;
            /* 设置最大显示行数为4行 */
          }
        }
      }
    }
  }

  .contact-us-content {
    margin: 20px 12px 26px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
    padding: 14px 12px 30px 0;
    box-sizing: border-box;

    .job-name {
      // width: 147px;
      height: 44px;
      background: linear-gradient(270deg,
          #2a7eff 0%,
          #c8dfff 0.01%,
          rgba(189, 217, 255, 0) 100%);
      border-left: 4px solid #2a7eff;
      padding: 12px 0 12px 25px;
      box-sizing: border-box;
      color: #262d37;
      font-family: PingFangSCBold;
      font-size: 14px;
      line-height: 20px;
    }

    .job-name-en {
      font-family: Poppins-SemiBold;
    }

    .job-content {
      margin-left: 19px;
      display: flex;
      position: relative;

      .job-line {
        position: absolute;
        width: 1px;
        height: 100%;
        background: rgba(55, 55, 55, 0.2);
        top: 27px;
        bottom: 80px;
      }

      .job-right {
        display: flex;
        flex-direction: column;

        .job-title {
          display: flex;
          align-items: center;
          color: #262d37;
          font-family: PingFangSCBold;
          font-size: 14px;
          margin-top: 20px;

          .line {
            width: 17.5px;
            height: 1px;
            background: rgba(55, 55, 55, 0.2);
            margin-right: 6px;
          }
        }

        .job-title-en {
          font-family: Poppins-Medium;
        }

        .job-context {
          margin-left: 24px;
          margin-top: 15px;
          color: rgba(55, 55, 55, 0.6);
          text-align: justify;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
          /* 25.2px */
        }

        .job-context-en {
          font-family: Poppins-Regular;
        }
      }
    }

    .job-contacts,
    .job-email {
      color: #2a7eff;
      font-family: PingFangSCBold;
      font-size: 16px;
      line-height: 217.5%;
      /* 34.8px */
      margin-left: 43px;
    }

    .job-contacts-en {
      font-family: Poppins-Medium;
    }

    .job-contacts {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 20px;

      .line {
        width: 17.5px;
        height: 1px;
        background: rgba(55, 55, 55, 0.2);
        margin-right: 6px;
      }
    }
  }

  .contact-us-bottom {
    padding: 16px 15px 0px 12px;
    box-sizing: border-box;
    width: 100%;
    // height: 87px;
    background: #f4f9ff;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .contact-us-bottom-pic {
      background-size: contain;
      width: 80px;
      height: 71px;
      align-self: flex-end;
    }

    .bottom-block {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      align-self: center;

      .contact-email {
        font-family: PingFangSCHeavy;
        font-size: 14px;
        color: #262d37;
        line-height: 20px;
      }

      .contact-email-en {
        font-family: Poppins-Regular;
        font-weight: 400;
        color: #373737;
      }

      .email-style-en {
        font-family: Poppins-Medium;
        color: #373737;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
      }

      .contact-notes {
        color: rgba(55, 55, 55, 0.6);
        font-family: PingFangSCBold;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin: 9px 0;
      }
    }

  }

  .contact-us-bottom-en {
    padding-top: 13px;
  }
}
</style>
