<template>
    <!-- 发展团队 -->
    <div class="develop">
        <div class="developTeam">
            <van-swipe @change="onChange">
                <van-swipe-item v-for="(item, index) in organizationalList" :key="index">
                    <img class="swipeImg" :src="$config.proxy + item.swiperPic" alt="">
                </van-swipe-item>
            </van-swipe>
            <div :class="['context', $i18n.locale == 'en' ? 'context-en' : '']"
                v-html="context ? context : organizationalList[0]?.intro"></div>
        </div>
        <div :class="['developList', $i18n.locale == 'en' ? 'developList-en' : '']">
            <div class="list" v-for="(item, index) in teamSmallIconList" :key="index">
                <img class="icon" :src="$config.proxy + item.pic" alt="">
                <div class="info">
                    <div
                        :class="['num', ((item.num.length > 5 && index == 0) || (item.num.length > 5 && index == 3)) ? 'num1' : 'num']">
                        {{ item.num }}</div>
                    <div :class="['name', $i18n.locale == 'en' ? 'name-en' : '']">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            context: '',
        }
    },
    props: {
        teamSmallIconList: {
            type: Array,
            required: false
        },
        organizationalList: {
            type: Array,
            required: false
        }
    },
    methods: {
        onChange(index) {
            this.context = this.organizationalList[index].intro
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .van-swipe__indicator {
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    background: #D8E4F3;
    opacity: 1;
}

::v-deep .van-swipe__indicator--active {
    width: 20px;
    height: 7px;
    border-radius: 3.5px;
    background: #2A7EFF;
}
</style>
<style lang="scss" scoped>
.develop {
    padding: 20px 7px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    margin: 0 12px;

    .developTeam {
        padding: 0 8px;

        .swipeImg {
            width: 100%;
        }

        .context {
            color: rgba(55, 55, 55, 0.80);
            font-family: PingFangSCMedium;
            font-size: 14px;
            line-height: 189.5%;
            margin: 15px 0 20px 0;
        }

        .context-en {
            font-family: Poppins-Regular;
            text-align: justify;

            ::v-deep p {
                word-break: keep-all !important;
            }
        }
    }

    .developList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .list {
            width: 149px;
            height: 127px;
            border-radius: 6px;
            border: 1px solid #FFF;
            background: linear-gradient(180deg, #F1F3F8 0%, #FFF 100%);
            box-shadow: 6px 4px 25px 0px rgba(206, 211, 225, 0.80), -6px -5px 18px 0px rgba(255, 255, 255, 0.80), -2px -2px 7px 0px rgba(255, 255, 255, 0.80), 2px 3px 7px 0px rgba(206, 211, 225, 0.80);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .icon {
                width: 122px;
                height: 67px;
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: center;

                .num {
                    color: #2A7EFF;
                    font-family: PingFangSCHeavy;
                    font-size: 22px;
                    font-weight: 400;
                }

                .num1 {
                    font-size: 16px;
                }

                .name {
                    color: #373737;
                    font-family: PingFangSCBold;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 26.1px;
                }
                .name-en {
                    font-family: Poppins-Regular;
                }
            }
        }

        .list:nth-child(1),
        .list:nth-child(4) {
            width: 168px;
            height: 75px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            .icon {
                position: absolute;
                left: 0;
                width: 91px;
                height: 52px;
            }

            .info {
                z-index: 9;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 10px;
            }
        }

        .list:nth-child(1) {
            margin-right: 10px;
        }

        .list:nth-child(3) {
            margin-top: -40px;
            margin-right: 10px;
        }

        .list:nth-child(4) {
            align-self: flex-end;
        }
    }
}
</style>