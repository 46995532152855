<template>
  <div :class="['developmentHistory', $i18n.locale == 'en' ? 'developmentHistory-en' : '']">
    <div class="content">
      <div class="developCard">
        <div class="listBox">
          <div class="list" v-for="(item, index) in list" :key="index">
            <div class="line"></div>
            <img class="dot" src="@/assets/images/about/dot.png" alt="" />
            <div class="card">
              <div class="year">{{ item.year }}年</div>
              <div class="context" v-html="item.context"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  courseList,
} from "@/api/home/index";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getcourseList();
  },
  methods: {
    getcourseList() {
      courseList({ pageNum: 1, pageSize: 9999, status: "1" }).then((res) => {
        this.list = res.rows.reverse();
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss" scoped>
.developmentHistory {
  max-height: 519px;
  margin: 0 12px;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);

  .content {
    width: 100%;
    max-height: 479px;
    overflow-y: auto;


    .developCard {
      .listBox {
        .list {
          display: flex;
          margin-top: 15px;
          // border-left: 1px dashed rgba(55, 55, 55, 0.20);
          position: relative;
          margin-left: 11px;

          .dot {
            width: 22px;
            height: 22px;
            position: absolute;
            left: -11px;
            top: 13px;
          }

          .line {
            border-left: 1px dashed rgba(55, 55, 55, 0.2);
            width: 1px;
            height: 100%;
            position: absolute;
            top: 25px;
          }

          .card {
            border-radius: 8px;
            background: #f1f3f8;
            margin-left: 22px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 12px 7px;
            box-sizing: border-box;
            width: 100%;

            .year {
              padding: 2px 0 5px 6px;
              border-left: 3px solid #2a7eff;
              border-bottom: 1px solid #2a7eff;
              color: #2a7eff;
              font-family: PingFangSCBold;
              font-size: 16px;
              margin-bottom: 10px;
            }

            .context {
              color: rgba(55, 55, 55, 0.7);
              font-family: PingFangSCMedium;
              font-size: 14px;
              line-height: 140%;

              ::v-deep p {
                margin-bottom: 5px;
              }

              ::v-deep strong {
                display: block;
                color: #373737;
                font-family: PingFangSCBold;
                font-size: 14px;
                line-height: 217.5%;
                //   margin-top: 14px;
                /* 30.45px */
              }
            }
          }
        }

        .list:first-child {
          margin-top: 0;
        }

        .list:last-child {
          .line {
            top: 0;
          }
        }
      }
    }
  }
}

.developmentHistory-en {
  .content {
    .developCard {
      .listBox {
        .list {
          .card {
            .year {
              font-family: Poppins-Medium;
            }

            .context {
              font-family: Poppins-Regular;

              ::v-deep strong {
                font-family: Poppins-Medium;
              }
            }
          }
        }
      }
    }
  }
}
</style>