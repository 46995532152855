<template>
    <div :class="['bottomBar',$i18n.locale == 'en' ? 'bottomBar1' : '']">
        <img class="logo" src="@/assets/images/home/bottomLogo.png">
        <div class="line"></div>
        <div class="content">
            <div class="infoList" v-for="(item, index) in infoList" :key="index">
                <img class="icon" :src="item.icon" alt="">
                <div class="context">{{ index== 2 ? $t('bottomBar.address') : item.context }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            infoList: [
                {
                    icon: require('@/assets/images/home/icon_phone.png'),
                    // context: '0351-7995913'
                    context: '(+86)-10-8282-6013'
                }, {
                    icon: require('@/assets/images/home/icon_email.png'),
                    // context: 'info-ty@etres.cn'
                    context: 'info@etres.cn'
                }, {
                    icon: require('@/assets/images/home/icon_location.png'),
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.bottomBar {
    width: 100%;
    height: 141px;
    background: #A6B1C4;
    // margin-top: 25px;
    padding: 15px 20px 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .logo {
        width: 100px;
    }

    .line {
        width: 1px;
        height: 100%;
        background: rgba(255, 255, 255, 0.60);
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .infoList {
            display: flex;
            align-items: center;

            .icon {
                width: 16px;
            }

            .context {
                width: 150px;
                white-space: wrap;
                color: #FFF;
                font-family: PingFangSCMedium;
                font-size: 12px;
                line-height: 1.5;
                margin-left: 13px;
            }
        }
        .infoList:last-child {
            align-items: start;
        }
    }
}
.bottomBar1 {
    height: 195px;
}
</style>