<template>
    <div :class="['domesticProjects', $i18n.locale == 'en' ? 'domesticProjects-en' : '']">
        <div class="projects" v-for="(item, index) in projectsList" :key="index" @click="jump(item)">
            <div class="projects-bg">

                <div class="projects-context">
                    <div class="title-box">
                        <div class="context-title">{{ item.name }}</div>
                    </div>
                    <div class="next-img">
                        <img src="@/assets/images/product/next.svg" alt="">
                    </div>
                </div>
                <div class="line"></div>
                <div class="context-content" v-html="item.context.replace(/\n/g, '<br>')"></div>

                <div class="projects-pic">
                    <img :src="$config.proxy + item.picBg">
                </div>
            </div>
            <!-- <img class="endlogo"
                :src="index % 2 == 0 ? require('@/assets/images/product/endlogo.png') : require('@/assets/images/product/endlogo1.png')"
                alt=""> -->
        </div>
        <div v-if="projectsList.length == 0"
            style="display: flex; flex-direction: column;
            justify-content: center; align-items: center; width: 100%; color: #262D37; font-size: 16px; font-family: 'PingFangSCMedium'; margin-bottom: 80px;">
            <img src="@/assets/images/tools/tools.png" style="width: 197px; height: 197px;" alt="">

            {{ $i18n.locale == 'en' ? 'Coming soon! Please expecting!' : '完善中，敬请期待!' }}

        </div>
        <div class="bottom">
            <Statistics></Statistics>
        </div>
    </div>
</template>
<script>
import Statistics from "@/components/Statistics.vue";
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
    components: {
        Statistics,
    },
    props: {
        projectsList: {
            type: Array,
            required: false
        },
    },
    methods: {
        jump(item) {
            // console.log(item);
            if (item.detailContent) {
                this.$router.replace({
                    path: '/productDetail',
                    query: {
                        id: item.id,
                        // item: JSON.stringify(item)
                    }
                })
            } else {
                // 提示没有详情
                Toast(this.$i18n.locale == 'en' ? 'No details' : '该产品暂无详情');
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.domesticProjects {
    .projects {
        margin-top: 12px;

        .projects-bg {
            margin: 0 12px;
            padding: 0 14px;
            box-sizing: border-box;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.50);
            width: 351px;
            border: 1px solid #FFFFFF;

            .projects-pic {
                margin: 16px 0;
                border-radius: 10px;
                width: 323px;
                height: 181px;
                border: 2px solid #FFFFFF;
                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }


            .projects-context {
                box-sizing: border-box;
                margin: 10px 0 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title-box {
                    .context-title {
                        color: #262D37;
                        // color: #2A7EFF;
                        font-family: PingFangSCBold;
                        font-size: 16px;
                        width: 274px;
                        line-height: 19px;
                    }


                }

                .next-img {
                    width: 33px;
                    height: 33px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .line {
                background: #2A7EFF;
                width: 50px;
                height: 3px;
                margin-top: 4px;
            }

            .context-content {
                text-align: justify;
                margin-top: 16px;
                color: rgba(55, 55, 55, 0.60);
                font-family: 'PingFangSCMedium';
                font-size: 14px;
                line-height: 181.5%;
                // 最多展示n行，溢出显示...
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }

        .endlogo {
            width: 118px;
            height: 101px;
            position: absolute;
            right: 12px;
            bottom: -45px;
        }
    }

    .projects:first-child {
        margin-top: 0;
    }

    .bottom {
        margin-top: 16px;
    }
}

.domesticProjects-en {
    .projects {
        .projects-bg {
            .projects-context {
                .title-box {
                    .context-title {
                        font-family: Poppins-Medium;
                        word-break: keep-all !important;
                        line-height: 19px;
                    }
                }

            }

            .context-content {
                font-family: Poppins-Regular;
                text-align: justify;
                word-break: keep-all !important;
            }
        }
    }
}
</style>