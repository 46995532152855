<template>
    <div class="cooperativeEnterprises">
        <div :class="['circleList', $i18n.locale=='en' ? 'circleList-en' : '']">
            <div class="circle" v-for="(item, index) in circleList" :key="index">
                <CircleCard :circleData="item.circleData"></CircleCard>
                <div class="circleName">{{ item.name }}</div>
            </div>
        </div>
        <swiper :options="mySwiperOption" ref="mySwiper" v-if="firstBannerList.length > 1">
            <swiper-slide v-for="(item, i) in firstBannerList" :key="i">
                <div class="img">
                    <img :src="$config.proxy + item.image" />
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiperCard">
            <swiper :options="mySwiperOption1" ref="mySecondSwiper" v-if="secondBannerList.length > 1">
                <swiper-slide v-for="(item, i) in secondBannerList" :key="i">
                    <div class="img">
                        <img :src="$config.proxy + item.image" />
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <swiper :options="mySwiperOption" ref="myThirdSwiper" v-if="thirdBannerList.length > 1">
            <swiper-slide v-for="(item, i) in thirdBannerList" :key="i">
                <div class="img">
                    <img :src="$config.proxy + item.image" />
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { iconList,smallIconList } from "@/api/home/index";
import CircleCard from '@/components/CircleCard.vue'
// 局部引入 vue-awesome-swiper 及其样式
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
    name: 'MyTestVueAwesomeSwipe',
    components: {
        // 注册 vue-awesome-swiper 组件
        swiper,
        swiperSlide,
        CircleCard
    },
    data() {
        return {
            circleList: [
                {
                    circleData: {
                        currentRate: 0,
                        color: '#FF902A',
                        layerColor: '#FF902A33',
                    },
                    name: ''
                }, {
                    circleData: {
                        currentRate: 0,
                        color: '#14C2C1',
                        layerColor: '#14C2C133',
                    },
                    name: ''
                }, {
                    circleData: {
                        currentRate: 0,
                        color: '#2A7EFF',
                        layerColor: '#2A7EFF33',
                    },
                    name: ''
                }, {
                    circleData: {
                        currentRate: 0,
                        color: '#FDD337',
                        layerColor: '#FDD33733',
                        text: '300+'
                    },
                    name: ''
                },
            ],
            mySwiperOption: {
                loop: true,
                spaceBetween: 10,
                loopAdditionalSlides: 100,
                slidesPerView: 3.3,
                speed: 3000,
                //修改swiper自己或子元素时，自动初始化swiper
                observer: true,
                //修改swiper的父元素时，自动初始化swiper
                observeParents: true,
                autoplay: {
                    delay: 3000, //自动切换的时间间隔
                    // reverseDirection: false,//反向滚动
                    // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                    disableOnInteraction: false,
                },
            },
            mySwiperOption1: {
                loop: true,
                spaceBetween: 10,
                loopAdditionalSlides: 100,
                slidesPerView: 3.3,
                speed: 3000,
                //修改swiper自己或子元素时，自动初始化swiper
                observer: true,
                //修改swiper的父元素时，自动初始化swiper
                observeParents: true,
                autoplay: {
                    delay: 3000, //自动切换的时间间隔
                    reverseDirection: true,//反向滚动
                    // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                    disableOnInteraction: false,
                },
            },
            httpImageUrl: require("@/assets/images/home/swiper.png"),
            firstBannerList: [],
            secondBannerList: [],
            thirdBannerList: []
        }
    },
    methods: {
        init() {
            smallIconList({ pageNum: 1, pageSize: 4, type: 2 }).then((res) => {
                let arr = []
                res.rows.map((i) => {
                    if(i.num.indexOf('+') != -1) {
                        this.circleList[3].circleData.text = i.num
                        this.circleList[3].name = i.appTitle
                        this.circleList[3].circleData.currentRate = 75
                    } else {
                        arr.push(i)
                    }
                    return arr
                })
                this.circleList[0].circleData.currentRate = Number(arr[0].num.split("%")[0])
                this.circleList[0].name = arr[0].appTitle
                this.circleList[1].circleData.currentRate = Number(arr[1].num.split("%")[0])
                this.circleList[1].name = arr[1].appTitle
                this.circleList[2].circleData.currentRate = Number(arr[2].num.split("%")[0])
                this.circleList[2].name = arr[2].appTitle
            })
            iconList({ pageNum: 1, pageSize: 999 }).then((res) => {
                this.firstBannerList = res.rows.filter((item) => {
                    return item.rowNum == 1 && item.status == 1
                })
                this.secondBannerList = res.rows.filter((item) => {
                    return item.rowNum == 2 && item.status == 1
                })
                this.thirdBannerList = res.rows.filter((item) => {
                    return item.rowNum == 3 && item.status == 1
                })
            })
        }
    },
    created() {
        this.init();
    },
}
</script>

<style lang="scss" scoped>
.cooperativeEnterprises {
    margin: 0 12px;
    margin-bottom: 25px;

    .circleList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .circle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .circleName {
                color: #373737;
                font-family: PingFangSCBold;
                font-size: 12px;
                margin-top: 10px;
            }
        }
    }
    .circleList-en {
        .circle {
            .circleName {
                font-family: Poppins-Medium;
            }
        }
    }

    .swiperCard {
        margin: 5px 0;
    }

    .img {
        width: 100px;
        height: 50px;
        padding: 5px 10px;
        background: #FFF;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            // width: 100%;
            height: 100%;
        }
    }

}
</style>