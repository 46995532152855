<template>
    <div class="about">
        <!-- banner开始 -->
        <div :class="['aboutBanner', $i18n.locale == 'en' ? 'aboutBannerEn' : '']">
            <div class="ab_title">{{ $t('about.title1') }}</div>
            <div class="ab_subTitle">{{ $t('about.title2') }}</div>
            <div class="ab_subTitle">{{ $t('about.title3') }}</div>
        </div>
        <!-- banner结束 -->
        <!-- 公司简介开始 -->
        <TitleCard :titleInfo="$t('about.titleInfo')"></TitleCard>
        <aboutUs></aboutUs>
        <!-- 公司简介结束 -->

        <!-- 发展历程开始 -->
        <TitleCard :titleInfo="$t('about.titleInfo1')"></TitleCard>
        <developmentHistory></developmentHistory>
        <!-- 发展历程结束 -->

        <!-- 企业理念开始 -->
        <TitleCard :titleInfo="$t('about.titleInfo2')"></TitleCard>
        <Philosophy></Philosophy>
        <!-- 企业理念结束 -->
        <!-- 专业团队开始 -->
        <TitleCard :titleInfo="$t('about.titleInfo3')"></TitleCard>
        <professionalTeam></professionalTeam>
        <!-- 专业团队结束 -->
        <!-- 企业资质开始-->
        <TitleCard :titleInfo="$t('about.titleInfo4')"></TitleCard>
        <enterpriseQualification></enterpriseQualification>
        <!-- 企业资质结束-->
        <!-- 团队风采开始-->
        <TitleCard :titleInfo="$t('about.titleInfo5')"></TitleCard>
        <teamStyle></teamStyle>
        <!-- 团队风采结束 -->




    </div>
</template>

<script>
import TitleCard from "@/components/TitleCard.vue";
// import ButtonCard from "@/components/ButtonCard.vue";
import aboutUs from './component/aboutUs.vue';
import developmentHistory from './component/developmentHistory.vue';
import Philosophy from './component/Philosophy.vue';
import professionalTeam from './component/professionalTeam.vue';
import enterpriseQualification from './component/enterpriseQualification.vue';
import teamStyle from './component/teamStyle.vue';
import { addStay } from "@/api/home/index";

export default {
    components: {
        TitleCard,
        // ButtonCard,
        aboutUs,
        developmentHistory,
        Philosophy,
        professionalTeam,
        enterpriseQualification,
        teamStyle

    },
    data() {
        return {
            stopTime: '',
            titleInfo: {
                name: "公司简介",
                enName: "COMPANY PROFILE",
            },
            titleInfo1: {
                name: "发展历程",
                enName: "DEVELOPMENT HISTORY",
            },
            titleInfo2: {
                name: "企业理念",
                enName: "CORE technology",
            },
            titleInfo3: {
                name: "专业团队",
                enName: "Professional team",
            },
            titleInfo4: {
                name: "企业资质",
                enName: "Enterprise Qualification",
            },
            titleInfo5: {
                name: "团队风采",
                enName: "Team Style",
            }
        }
    },
    created() {
        this.stopTime = new Date().getTime()
    },
    beforeDestroy() {
        this.stopTime = new Date().getTime() - this.stopTime
        addStay({ unitType: 1, type: 1, time: this.stopTime }).then((res) => {
        })
    },
}
</script>

<style lang="scss" scoped>
.about {

    // margin-top: 40px;
    .aboutBanner {
        box-sizing: border-box;
        width: 100%;
        height: 220px;
        color: #262d37;
        font-weight: 400;
        padding-top: 71px;
        padding-bottom: 49px;
        background: url("@/assets/images/about/about_banner.png") no-repeat center center;
        background-size: cover;

        .ab_title {
            margin-left: 30px;
            font-family: PingFangSCHeavy;
            font-size: 25px;
            line-height: 217.5%;
            /* 54.375px */
        }

        .ab_subTitle {
            margin-left: 30px;
            margin-top: 8px;
            font-family: PingFangSCBold;
            font-size: 14px;
            line-height: 95.5%;
            /* 13.37px */
        }
    }

    .aboutBannerEn {
        .ab_title {
            margin-left: 30px;
            color: #262D37;
            font-size: 20px;
            line-height: 217.5%;
            font-family: Poppins-SemiBold;
        }

        .ab_subTitle {
            margin-left: 30px;
            width: 65%;
            font-size: 12px;
            line-height: 150%;
            font-family: Poppins-Regular;
        }
    }
}</style>