import { api, formatParams, config } from "@/utils/request";

// 监听停留时长
export function addStay(data) {
  return api(
    Object.assign(formatParams("post"), {
      url: `${config.proxy}/web/stay/save`,
      data: data
    })
  );
}

// 获取公司简介 团队 核心技术
export function companyList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/company/list`,
    })
  );
}
// 获取小图标列表
export function smallIconList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/icons/list`,
    })
  );
}

// 获取特色产品列表
export function productList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/product/list`,
    })
  );
}
// 获取发展历程列表
export function courseList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/course/list`,
    })
  );
}
// 获取公司动态列表
export function dynamicList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/dynamic/list`,
    })
  );
}
// 获取动态详情
export function getDynamicDetail(params) {
  return api(
    Object.assign(formatParams("GET"), {
      url: `${config.proxy}/web/dynamic/getById/${params.id}`,
    })
  );
}
// 获取企业理念列表
export function teamList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/team/list`,
    })
  );
}
// 获取专业团队列表
export function professionList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/profession/list`,
    })
  );
}
// 获取团队风采列表
export function mienList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/mien/list`,
    })
  );
}
// 获取组织结构列表
export function organizationalList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/organizational/list`,
    })
  );
}
// 获取工作岗位列表
export function postList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/post/list`,
    })
  );
}

// 获取合作企业
export function iconList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/icon/list`,
    })
  );
}
// 获取企业资质
export function qualificationList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/qualification/list`,
    })
  );
}
// 获取招聘邮箱列表
export function emailList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/email/list`,
    })
  );
}

// 获取产品中心-项目类型列表
export function projectTypeList(params) {
  return api(
    Object.assign(formatParams("GET", params), {
      url: `${config.proxy}/web/product/dictdata`,
    })
  );
}